import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import PDF from "../../documents/cryptography.pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdf = () => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [scale, setScale] = useState(1); // Initial scale value

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const zoomIn = () => {
		setScale(scale + 0.5);
	};

	const zoomOut = () => {
		if (scale > 0.1) {
			// Prevents scale from being negative or 0
			setScale(scale - 0.5);
		}
	};

	const nextPage = () => {
		if (pageNumber <= numPages) {
			setPageNumber(pageNumber + 1);
		}
	};

	const prevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	};
	return (
		<PdfBody>
			<Pdfcon>
				<TitlePdf>
					<h1>Read our NewsLetter</h1>
					<p>Published on 1st October 2023</p>
				</TitlePdf>
				<ButtonContainer>
					<Button onClick={prevPage} disabled={pageNumber === 1}>
						Prev
					</Button>
					<Button onClick={zoomOut} disabled={scale <= 0.1}>
						{" "}
						{/* Disable zoomOut when scale is minimal */}
						Zoom Out
					</Button>

					<p>
						Page {pageNumber} of {numPages}
					</p>
					<Button onClick={zoomIn}>Zoom In</Button>
					<Button onClick={nextPage} disabled={pageNumber === numPages}>
						Next
					</Button>
				</ButtonContainer>
				<Doc>
					<ActualDoc
						file={PDF}
						onLoadSuccess={onDocumentLoadSuccess}
						onContextMenu={(e) => e.preventDefault()}>
						<Page
							scale={scale}
							pageNumber={pageNumber}
							renderTextLayer={false}
							renderAnnotationLayer={false}
						/>
					</ActualDoc>
				</Doc>
			</Pdfcon>
		</PdfBody>
	);
};

export default pdf;

const PdfBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
`;

const Pdfcon = styled.div`
  display: flex;
  margin: 0 auto;
  width: 80%;
  flex-direction: column;
`;

const TitlePdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > h1 {
    font-size: 50px;
    color: #975ab6;
    margin: 0;
    margin-bottom: 20px;
  }
  > p {
    font-size: 20px;
    font-family: "Mulish";
    font-weight: 500px;
  }
`;

const Doc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 250px;
  margin-top: 50px;
`;

const ActualDoc = styled(Document)`
  display: flex;
  justify-content: center;
  user-select: none;
  border: 20px solid #f5f5f5;
  border-radius: 30px;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    border-radius: 30px;
    /* @media (max-width: 320px) {
      scale: 1.5;
    }
    @media (max-width: 425px) {
      scale: 1.5;
    } */
  }
  @media (max-width: 425px) {
    .react-pdf__Page {
      scale: 1.4;
      border: 5px solid #f5f5f5;
      border-radius: 30px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  > p {
    font-size: 20px;
    font-family: "Mulish";
  }
`;
const Button = styled.button`
  color: #4f1964;
  font-family: "Mulish";
  margin-right: 10px;
  margin-left: 10px;
  padding: 2%;
  width: 120px;
  font-size: 20px;
  border-radius: 20px;
  border-color: transparent;
  @media print {
    display: none;
  }
`;
