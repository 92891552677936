import React from "react";
import styled from "styled-components";
import Banner from "./ContactUsComponent/Banner";
import Contact from "./ContactUsComponent/Contact";
import background from "../assets/backgrounds/background.png";
import Career from "./ContactUsComponent/Career";
import useDocumentTitle from "../Titlemanagement.jsx";
const ContactUs = ({ Writeups }) => {
  useDocumentTitle("Sekolah Al-Ameerah Al-Hajjah Maryam | Contact Us");

  return (
    <ContactUsBody>
      <Banner Writeups={Writeups} />
      <Contact Writeups={Writeups} />
      <Career Writeups={Writeups} />
    </ContactUsBody>
  );
};

export default ContactUs;

const ContactUsBody = styled.div`
  min-height: calc(100vh);
  background-image: url(${background});
  background-size: contain;
`;
