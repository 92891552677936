import React from "react";
import styled from "styled-components";
import Banner from "./LearningComponents/Banner";
import Academics from "./LearningComponents/Academics";
import EarlyYears from "./LearningComponents/EarlyYears";
import PrimarySchool from "./LearningComponents/PrimarySchool";
import Services from "./LearningComponents/Services";
import background from "../assets/backgrounds/background.png";
import Religious from "./LearningComponents/Religious";
import Services2 from "./LearningComponents/Services2";
import useDocumentTitle from "../Titlemanagement.jsx";
import Curriculum from "./LearningComponents/Curriculum";
import Assessments from "./LearningComponents/Assessments";
import Services2_2 from "./LearningComponents/Services2_2";
const Learning = ({
  academics,
  earlyYears,
  primarySchool,
  supportServices,
  RA,
  assessments,
  curriculums,
  Writeups,
}) => {
  useDocumentTitle("Sekolah Al Ameerah Al Hajjah Maryam | Learning");

  return (
    <Body>
      <Banner Writeups={Writeups} />
      <Academics scroll={academics} Writeups={Writeups} />
      <Curriculum scroll={curriculums} Writeups={Writeups} />
      <EarlyYears scroll={earlyYears} Writeups={Writeups} />
      <PrimarySchool scroll={primarySchool} Writeups={Writeups} />
      <Religious scroll={RA} Writeups={Writeups} />
      <Assessments scroll={assessments} Writeups={Writeups} />
      <Services scroll={supportServices} Writeups={Writeups} />
      <Services2_2 Writeups={Writeups} />
    </Body>
  );
};

export default Learning;

const Body = styled.div`
  min-height: calc(100vh);
  background-image: url(${background});
  background-size: contain;
`;
