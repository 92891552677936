import React, { useEffect } from "react";
import styled from "styled-components";
import earlyYears from "../../assets/sectionPicture/religious.png";
const Religious = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <ReligiousBody ref={scroll} id='RA'>
      <ReligiousCon>
        <img src={earlyYears} alt='early-years' />
        <WriteupsBod>
          <h1>{Writeups[0].Religious.title}</h1>

          <p>{Writeups[0].Religious.p1}</p>
        </WriteupsBod>
      </ReligiousCon>
    </ReligiousBody>
  );
};

export default Religious;

const ReligiousBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
`;
const ReligiousCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  width: 100%;
  > img {
    width: 500px;

    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-right: auto;
    }
  }
  /* > img:nth-child(2) {
    @media (max-width: 820px) {
      display: none;
    }
  } */
`;

const WriteupsBod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 9%;
  @media (max-width: 820px) {
    margin-right: 0;
  }
  > h1 {
    font-size: 50px;
    color: #55833e;
    margin: 0;
    margin-left: 30px;
    margin-right: 30px;
  }
  > p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    margin-left: 30px;
    margin-top: 15px;
    margin-right: 30px;
    text-align: justify;
  }
  > p:first-of-type {
    margin-top: 25px;
  }
  > li {
    font-family: "Mulish";
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    margin-left: 50px;
    margin-top: 2px;
    margin-right: 50px;
    text-align: justify;
  }

  > li:first-of-type {
  }
`;
