import React from "react";
import styled from "styled-components";
import { Writeups } from "../../constant";
import leaf from "../../assets/backgrounds/leaf-background-purple.png";
const Services2 = () => {
  return (
    <ServicesBody>
      <ServicesCon>
        <Containers>
          <Writeupss>
            <h1>{Writeups[0].SupportServices.Physiotherapy.title}</h1>
            <p>{Writeups[0].SupportServices.Physiotherapy.p1}</p>
          </Writeupss>
        </Containers>
        <Containers>
          <Writeupss>
            <h1>{Writeups[0].SupportServices.occupational.title}</h1>
            <p>{Writeups[0].SupportServices.occupational.p1}</p>
          </Writeupss>
        </Containers>
        <Containers>
          <Writeupss>
            <h1>{Writeups[0].SupportServices.speech.title}</h1>
            <p>{Writeups[0].SupportServices.speech.p1}</p>
          </Writeupss>
        </Containers>
        <Containers2>
          <Writeupss>
            <h1>{Writeups[0].SupportServices.Psychology.title}</h1>
            <p>{Writeups[0].SupportServices.Psychology.p1}</p>
          </Writeupss>
        </Containers2>
      </ServicesCon>
    </ServicesBody>
  );
};

export default Services2;

const ServicesBody = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
`;

const ServicesCon = styled.div`
  display: flex;
  width: 84.5%;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (max-width: 876px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const Containers = styled.div`
  display: flex;
  width: 25%;
  background-color: #f5f5f5;
  padding: 2%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  border-radius: 20px;
  background-image: url(${leaf});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  @media (max-width: 768px) {
    width: 89.5%;
    margin-left: 5px;
  }
`;

const Writeupss = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > h1 {
    margin: 0;
    margin-bottom: 20px;
    flex-wrap: wrap;
    color: #975ab6;
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  > p {
    margin: 0;
    flex-wrap: wrap;
    font-family: "Mulish";
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

const Containers2 = styled.div`
  display: flex;
  width: 89.5%;
  background-color: #f5f5f5;
  flex-wrap: wrap;
  padding: 2%;
  border-radius: 30px;
  background-image: url(${leaf});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  @media (max-width: 768px) {
    width: 89.5%;
  }
`;
