import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";

const Contact = ({ Writeups }) => {
  const position = [51.505, -0.09];

  return (
    <ContactBody>
      <ContentTitle>
        <h1>Sekolah Al-Ameerah Al-Hajjah Maryam</h1>
      </ContentTitle>
      <ContentBody>
        <Content>
          <h1>{Writeups[0].ContactUs.title1}</h1>
          <p>Jasmina Hamid</p>
          <p>+673 8303860</p>
          <p>admin@saaahm.com</p>
        </Content>
        <LineSpacer />
        <Content>
          <h1>{Writeups[0].ContactUs.title2}</h1>
          <p>Nurus Saifulbahri</p>
          <p>+673 8303860</p>
          <p>principal@saaahm.com</p>
        </Content>
        <LineSpacer />
        <Content>
          <h1>{Writeups[0].ContactUs.title3}</h1>
          <p>admin@saaahm.com</p>
        </Content>
      </ContentBody>
      <ContentTitle isSecond>
        <h1>Pusat Ehsan Al-Ameerah Al-Hajjah Maryam</h1>
      </ContentTitle>
      <ContentBody>
        <Content purple>
          <h1>{Writeups[0].ContactUs.title4}</h1>
          <p>2650423</p>
          <p>2656380(fax)</p>
        </Content>
        <LineSpacer />
        <Content purple>
          <h1>{Writeups[0].ContactUs.title5}</h1>
          <p>pusatehsan.bn@gmail.com</p>
        </Content>
        <LineSpacer />
        <Content purple>
          <h1>{Writeups[0].ContactUs.title6}</h1>
          <p>pusatehsan.org.bn</p>
        </Content>
      </ContentBody>
      <ContentBody>
        <Content purple>
          <h1>{Writeups[0].ContactUs.title7}</h1>
          <p>Noel M. Mendiola</p>
          <p>2650423</p>
        </Content>
        <LineSpacer />
        <Content purple>
          <h1>{Writeups[0].ContactUs.title8}</h1>
          <p>Huda Muhin</p>
          <p>2650423</p>
          <p>huda.ahm28@gmail.com</p>
        </Content>
        <LineSpacer />
        <Content purple>
          <h1>{Writeups[0].ContactUs.title9}</h1>
          <p>Kamsiah Haji Zaman</p>
          <p>2650423</p>
        </Content>
      </ContentBody>
    </ContactBody>
  );
};

export default Contact;

const ContactBody = styled.section`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  width: 80%;
  display: flex;
  margin: 0 auto;
  justify-content: start;
  > h1 {
    color: ${(props) => (props.isSecond ? "#975AB6" : "#55833e")};
    font-size: 40px;
    margin-bottom: 0;
  }
`;

const ContentBody = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  background-color: #f5f5f5;
  justify-content: space-evenly;
  align-items: start;
  border-radius: 20px;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 70%;
  }
`;

const Content = styled.div`
  width: 30%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 80%;
  }

  > h1 {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: "Mulish";
    font-size: 25px;
    color: ${(props) => (props.purple ? "#975AB6" : "#55833e")};
  }
  > p {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 10px;
    font-family: "Mulish";
    font-size: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
  > p:last-of-type {
    margin-bottom: 20px;
  }
`;
const LineSpacer = styled.div`
  height: 10vh;
  width: 2px;
  background-color: grey;
  display: flex;
  margin: auto 0;
  @media (max-width: 768px) {
    height: 2px;
    width: 80%;
    margin: 0 auto;
  }
`;

const Writeup = styled.div`
  width: 80%; //100% of content body
  display: flex;
  flex-direction: column;
  > h3 {
    color: #55833e;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
  > p {
    color: black;
    font-size: 30px;
    font-family: "Mulish";
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
  > p:last-of-type {
    text-decoration: underline;
  }
`;
