import React, { useEffect } from "react";
import styled from "styled-components";
const Curriculum = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <CurBody ref={scroll} id='curriculum'>
      <CurTitle>
        <h1>{Writeups[0].OurCurriculum.title}</h1>
      </CurTitle>
      <Writeup>
        <p>{Writeups[0].OurCurriculum.p1}</p>
        <ol>
          {Writeups[0].OurCurriculum.p2.map((sub, index) => (
            <li key={index}>{sub}</li>
          ))}
        </ol>
        <p>{Writeups[0].OurCurriculum.p3}</p>
        <p>{Writeups[0].OurCurriculum.p4}</p>
      </Writeup>
    </CurBody>
  );
};

export default Curriculum;

const CurBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
`;
const CurTitle = styled.div`
  display: flex;
  width: 76%;
  margin: 0 auto;
  justify-content: start;
  h1 {
    font-size: 50px;
    margin: 0;
    margin-bottom: 10px;
    color: #55833e;
  }
`;

const Writeup = styled.div`
  display: flex;
  flex-direction: column;
  width: 76%;
  margin: 0 auto;
  p {
    font-size: 20px;
    font-family: "Mulish";
    text-align: justify;
  }
  li {
    font-size: 20px;
    font-family: "Mulish";
    text-align: justify;
  }
`;
