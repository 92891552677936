import styled from "styled-components";
import banner from "../../assets/backgrounds/header-title.png";
const Banner = ({ Writeups }) => {
  return (
    <BannerBody>
      <Body>
        <h1>{Writeups[0].Banner.OurSchool.title}</h1>
      </Body>
    </BannerBody>
  );
};

export default Banner;

const BannerBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
`;

const Body = styled.div`
  display: flex;
  background-image: url(${banner});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  width: 100%;
  max-width: 100%;
  min-height: 35vh;
  max-height: 50vh;
  height: 100%;
  justify-content: start;
  align-items: start;
  > h1 {
    color: white;
    font-size: 60px;
    margin: 0;
    margin-top: 100px;
    margin-left: 9.7%;
    @media (max-width: 333px) {
      text-align: center;
      margin-left: 5%;
    }
  }
`;
