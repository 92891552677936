import React, { useEffect } from "react";
import styled from "styled-components";
import appProcess from "../../assets/sectionPicture/application-process.png";
import cert from "../../assets/icons/certificate.png";
import tick from "../../assets/icons/tick.png";
import enrollProcess from "../../assets/sectionPicture/enrollment-process.png";
import leaf from "../../assets/backgrounds/leaf-background-purple.png";
const AppProcess = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("admission");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <AppProcessBody id='applicationSection'>
      <Content ref={scroll}>
        <img src={appProcess} alt='Application Process' loading='lazy' />
        <WriteupsApp>
          <h1>{Writeups[0].appProcess.title}</h1>
          <h2>{Writeups[0].appProcess.subTitle}</h2>
          <p>{Writeups[0].appProcess.content.p1}</p>
          <p>{Writeups[0].appProcess.content.p2}</p>
        </WriteupsApp>
      </Content>
      <Enrollment>
        <h1>{Writeups[0].documents.title}</h1>
      </Enrollment>
      <DocumentsCon>
        <Doc>
          <h1>{Writeups[0].documents.subtitle}</h1>
          <li>
            <img src={tick} />
            {Writeups[0].documents.p1}
          </li>
          <li>
            <img src={tick} />
            {Writeups[0].documents.p2}
          </li>
          <p>{Writeups[0].documents.note1}</p>
          <p>{Writeups[0].documents.note2}</p>
        </Doc>
        <img src={cert} alt='certIcon' />
      </DocumentsCon>
      <img src={enrollProcess} alt='enrollmentProcess' />
      <p>Zoom to for better readibility</p>
      {/* //maybe add media query */}
    </AppProcessBody>
  );
};

export default AppProcess;

const AppProcessBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  > img {
    width: 100%;
  }
  > p {
    margin: 0;
    font-size: 20px;
    text-align: center;
    font-style: "Mulish";
    color: #55833e;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 500px;
    @media (max-width: 600px) {
      width: 80%;
      margin-right: auto;
    }
    @media (max-width: 820px) {
      margin-right: auto;
    }
  }
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const WriteupsApp = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 8.11%;

  @media (max-width: 820px) {
    margin-left: 0;
  }
  > h1 {
    font-size: 50px;
    color: #975ab6;
    margin: 0;
    margin-left: 20px;
  }

  > h2 {
    text-align: start;
    color: black;
    font-family: "Mulish";
    font-weight: 900;
    margin-top: 16px;
    font-size: 26px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
  > p {
    font-family: "Mulish";
    font-weight: 500;
    margin-top: 16px;
    font-size: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
  }
`;

const Enrollment = styled.div`
  display: flex;
  width: 80%;
  justify-content: start;
  align-items: center;
  margin: 40px auto 0 auto;
  > h1 {
    font-size: 50px;
    color: #975ab6;
  }
`;

const DocumentsCon = styled.div`
  display: flex;
  margin: 10px auto 20px auto;
  width: 80%;
  padding: 20px;
  background-color: #f5f5f5;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url(${leaf});
  border-radius: 30px;
  justify-content: space-around;

  > img {
    width: 13%;
    @media (max-width: 820px) {
      display: none;
    }
  }
`;

const Doc = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  > h1 {
    font-size: 40px;
    color: #975ab6;
    margin-top: 10px;
    margin-bottom: 0;
  }
  > li {
    list-style: none;
    font-family: "Mulish";
    font-weight: 800;
    font-size: 20px;
    color: #975ab6;
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    > img {
      width: 5%;
      @media (max-width: 425px) {
        width: 10%;
      }
    }
  }
  > p {
    font-family: "Mulish";
    font-weight: 500;
    font-size: 16px;
    color: #975ab6;
    margin-top: 20px;
    margin: 0;
  }

  p:first-of-type {
    margin-top: 25px; /* Resetting the margin for the first <p> */
  }
  p:nth-of-type(2) {
    margin-top: 10px; /* Resetting the margin for the first <p> */
  }
`;
