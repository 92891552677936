import React, { useEffect } from "react";
import styled from "styled-components";
import scalendar from "../../assets/backgrounds/school-calendar.png";
import background from "../../assets/backgrounds/background.png";
const Cal = ({ scroll, Writeups, nav }) => {
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		console.log(params);
		const scrollToSection = params.get("admission");
		const test = document.getElementById(scrollToSection);
		if (test) {
			const offset = test.offsetTop;

			window.scrollTo({
				top: offset,
				behavior: "smooth",
			});
		}
	}, [window.location.search]);
	return (
		<CalendarBody ref={scroll} id='calendarSection'>
			<CalBodyCon>
				<ContentCal>
					<h1>{Writeups[0].cal.title}</h1>
					{/* <p>{Writeups[0].cal.p1}</p> */}
				</ContentCal>
				<Button onClick={nav}>{Writeups[0].cal.button}</Button>
			</CalBodyCon>
		</CalendarBody>
	);
};

export default Cal;

const CalendarBody = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 100px;
`;

const CalBodyCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60vh;
  max-height: 90vh;
  height: 100%;
  background-image: url(${scalendar});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
`;

const TitleCon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  > h1 {
  }
  /* margin-left: 25%; */
`;

const ContentCal = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 18%;
  height: 200px;
  @media (max-width: 900px) {
    margin-right: 0;
  }
  h1 {
    margin: 0;
    font-size: 50px;
    text-align: start;
    color: #975ab6;
    @media (max-width: 739px) {
      text-align: center;
    }
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  > p {
    margin: 0;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
  }
`;

const Button = styled.button`
  display: flex;
  border-radius: 25px;
  border: 1px solid transparent;
  padding: 25px 40px;
  font-size: 20px;
  background-color: #975ab6;
  color: white;
  font-weight: 700;
  font-family: "Mulish";
  &:hover {
	cursor: pointer;
  }
`;
