import React, { useState } from "react";

import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
const ExpandMenu = ({ route, toggleDrawer, scroll, adFees }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick = () => {
    toggleDrawer();
    setIsMenuOpen(false);
  };

  return (
    <SMenu>
      <MenuButton onClick={toggleMenu}>
        {route.name} {isMenuOpen ? <FaAngleDown /> : <FaAngleRight />}
      </MenuButton>
      <SubRoutesContainer isMenuOpen={isMenuOpen}>
        {route.subRoutes.map((subRoute) => (
          <SubRoute onClick={handleClick} to={subRoute.link} key={subRoute.name}>
            {subRoute.name}
          </SubRoute>
        ))}
      </SubRoutesContainer>
    </SMenu>
  );
};

export default ExpandMenu;

const SubRoutesContainer = styled.div`
  display: ${(props) => (props.isMenuOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 1rem;
`;
const SMenu = styled.div``;
const MenuButton = styled.div`
  font-size: 4.8vw;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  width: 100%;
`;

const SubRoute = styled(Link)`
  text-decoration: none;
  color: black;
  padding: 0.5rem;
  font-size: 4.3vw;
`;
