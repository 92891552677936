import React from "react";
import styled from "styled-components";
const Services2_2 = ({ Writeups }) => {
  return (
    <ServicesBod>
      <BoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].SupportServices.Physiotherapy.title}</h1>
            <p>{Writeups[0].SupportServices.Physiotherapy.p1}</p>
          </BoxContentAreas>
        </PerBoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].SupportServices.occupational.title}</h1>
            <p>{Writeups[0].SupportServices.occupational.p1}</p>
          </BoxContentAreas>
        </PerBoxCon>
      </BoxCon>
      <BoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].SupportServices.speech.title}</h1>
            <p>{Writeups[0].SupportServices.speech.p1}</p>
          </BoxContentAreas>
        </PerBoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].SupportServices.Psychology.title}</h1>
            <p>{Writeups[0].SupportServices.Psychology.p1}</p>
          </BoxContentAreas>
        </PerBoxCon>
      </BoxCon>
    </ServicesBod>
  );
};

export default Services2_2;

const ServicesBod = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  margin-bottom: 150px;
`;

const BoxCon = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  margin-top: 50px;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PerBoxCon = styled.div`
  display: flex;
  flex: 1;
  background-color: #e5d6eb;
  border-radius: 30px;

  h1 {
    color: #975ab6;
    font-size: 30px;
  }
  p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
  }
  li {
    font-family: "Mulish";
    font-size: 20px;
  }
`;

const BoxContentPrime = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 30px 25px;
`;

const BoxContentAreas = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 25px 30px 25px;
`;

const Closing = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  p {
    font-family: "Mulish";
    font-size: 20px;
  }
`;
