import React, { useState } from "react";
import styled from "styled-components";
import sFaci from "../../assets/backgrounds/school-facilities.png";
const Career = ({ Writeups }) => {
  const [fileName, setFileName] = useState(Writeups[0].career.label5);
  const [fileSelected, setFileSelected] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFileSelected(true);
    }
  };
  return (
    <CareerBody>
      <CareerCon>
        <ContainerMiddle>
          <CareerTitle>
            <h1>{Writeups[0].career.title}</h1>
            <p>{Writeups[0].career.p1}</p>
          </CareerTitle>
          <CareerDetailCon>
            <Form action='https://formsubmit.co/principal.pusatehsan@gmail.com' method='POST'>
              <InputContainer>
                <label>{Writeups[0].career.label1}</label>
                <input type='text' placeholder='' required />
              </InputContainer>
              <InputContainer>
                <label>{Writeups[0].career.label2}</label>
                <input type='text' placeholder='' required />
              </InputContainer>
              <InputContainer>
                <label>{Writeups[0].career.label3}</label>
                <input type='text' placeholder='' maxLength='7' required />
              </InputContainer>
              <CVLabel>{Writeups[0].career.label4}</CVLabel>
              <FileInputContainer fileSelected={fileSelected}>
                <label>
                  <input
                    type='file'
                    id='fileinput'
                    className='hidden-input'
                    onChange={handleFileChange}
                  />
                  <span
                    className='file-placeholder'
                    onClick={() => document.getElementById("fileInput").click()}>
                    {fileName}
                  </span>
                  <span
                    for='fileinput'
                    className='file-button'
                    onClick={() => document.getElementById("fileInput").click()}>
                    Browse File
                  </span>
                </label>
              </FileInputContainer>
              <Button type='submit'>Submit</Button>
            </Form>
          </CareerDetailCon>
        </ContainerMiddle>
      </CareerCon>
    </CareerBody>
  );
};

export default Career;

const CareerBody = styled.section`
  width: 100%;
  max-width: 100%;
  margin-bottom: 60px;
  margin-top: 100px;
`;

const CareerCon = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${sFaci});
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ContainerMiddle = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80%;
  margin-top: 50px;
`;
const CareerTitle = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  flex: 50;
  > h1 {
    font-size: 40px;
    color: #55833e;
    margin: 0;
    margin-bottom: 20px;
    text-align: start;
  }
  > p {
    font-size: 20px;
    margin: 0;
    text-align: start;
  }
`;

const CareerDetailCon = styled.div`
  display: flex;
  border-radius: 30px;
  background-color: #55833e;
  width: 30%; //use media query
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  flex: 40;

  @media (max-width: 1120px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 425px) {
    width: 90%;
  }
`;

const CareerDetails = styled.div`
  display: flex;
`;

const Form = styled.form`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.cv ? "row" : "column")};
  width: 100%;
  > label {
    color: white;
    font-size: 18px;
    font-family: "Mulish";
    margin-bottom: 5px;
    margin-top: 10px;
  }
  > input {
    font-size: 18px;
    font-family: "Mulish";
    height: 40px;
    border-radius: 15px;
    border-color: transparent;
  }
`;

const FileInputContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
  > label {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    font-family: "Mulish";
  }
  .hidden-input {
    display: none;
  }
  .file-placeholder {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    background-color: white;
    color: ${(props) => (props.fileSelected ? "black" : "grey")};
    padding-left: 12px;
    width: 70%;
    cursor: pointer;
  }
  .file-button {
    display: flex;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 30%;
    background-color: #b8b8b8;
    transition: background-color 0.3s;
  }
  .file-button:hover {
    cursor: pointer;
    background-color: #9b9b9b; /* Darker shade for hover effect */
  }
`;

const FileInput = styled.input`
  display: none;
  width: 500px;

  &::file-selector-button {
  }
`;

const Button = styled.button`
  display: flex;
  width: 45%;
  background-color: #7cb258;
  color: white;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding: 15px;
  border: 0px;
  font-size: 20px;
  font-family: "Mulish";
  border-radius: 30px;
`;

const CVLabel = styled.label`
  color: white;
  font-size: 18px;
  margin-top: 10px;
  font-family: "Mulish";
`;
