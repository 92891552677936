import React, { useEffect } from "react";
import styled from "styled-components";
import sFaci from "../../assets/backgrounds/school-facilities.png";
import therapyGarden from "../../assets/sectionPicture/Therapy-Garden.png";
import sensoryRoom from "../../assets/sectionPicture/swimming-pool.png";
import swimmingPool from "../../assets/sectionPicture/sensory-room.png";
import classroom from "../../assets/facilities/classroom.png";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FACILITIES } from "../../constant.jsx";

const Facilities = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("ourschool");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowBack
        className={className}
        style={{
          ...style,
          display: "block",

          fontSize: "100px !important",
          color: "#7CB258",
          zIndex: 10,
        }}
        onClick={onClick}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowForward
        className={className}
        style={{
          ...style,
          display: "block",

          fontSize: "20px",
          color: "#55833E",
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <FaciBody ref={scroll} id='schoolFacilities'>
      <WriteupBody>
        <h1>{Writeups[0].sFacilities.title}</h1>
        <p>{Writeups[0].sFacilities.p1}</p>
        <p>{Writeups[0].sFacilities.p2}</p>
      </WriteupBody>
      <FaciBodyCon>
        <Content>
          <Slider {...settings}>
            {FACILITIES.facName.map((fac, index) => (
              <Container key={index}>
                <img src={FACILITIES.facImg[index]} alt={fac} />
                <h3>{fac}</h3>
              </Container>
            ))}
          </Slider>
        </Content>
      </FaciBodyCon>
    </FaciBody>
  );
};

export default Facilities;

const FaciBody = styled.section`
  width: 100%;
  max-width: 100%;
`;

const WriteupBody = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
  > h1 {
    font-size: 50px;
    margin-bottom: 1%;
    color: #55833e;
  }
  > p {
    font-size: 20px;
    font-family: "Mulish";
    font-weight: 500;
    text-align: justify;
  }

  > p:nth-child(3) {
    margin-top: 0px;
  }
`;

const FaciBodyCon = styled.div`
  width: 100%;
  min-height: 50vh;
  max-height: 200vh;
  background-image: url(${sFaci});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  margin-bottom: 100px;
`;

const Content = styled.div`
  width: 80%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .slick-prev {
    width: 50px;
    height: 50px;
  }
  .slick-next {
    width: 50px;
    height: 50px;
  }
`;

const ImgContent = styled.div`
  display: flex !important;
`;
const Container = styled.div`
  margin-top: 50px;
  > img {
    width: 70%;
    margin: 0 auto;
    @media (max-width: 550px) {
      width: 90%;
    }
  }
  > h3 {
    text-align: center;
    color: #55833e;
    font-size: 23px;

    @media (max-width: 375px) {
      font-size: 20px;
    }
  }
`;
