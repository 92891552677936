import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
const Faq = ({ scroll, Writeups }) => {
  const [toggledrawer1, setToggleDrawer1] = useState(false);
  const [toggledrawer2, setToggleDrawer2] = useState(false);
  const toggle = () => {
    setToggleDrawer1(!toggledrawer1);
  };
  const toggle2 = () => {
    setToggleDrawer2(!toggledrawer2);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("admission");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);

  return (
    <FaqBody ref={scroll} id='faqSection'>
      <FaqContent>
        <FaqC>
          <h1>{Writeups[0].FAQ.title}</h1>
          <Question onClick={toggle}>
            <h2>{Writeups[0].FAQ.Q1.q}</h2> <StyledArrowUp toggledrawer={toggledrawer1} size={30} />
          </Question>
          <Answer toggledrawer={toggledrawer1}>
            <p>{Writeups[0].FAQ.Q1.a1}</p>
            <p>{Writeups[0].FAQ.Q1.a2}</p>
            <p>{Writeups[0].FAQ.Q1.a3}</p>
            <p>{Writeups[0].FAQ.Q1.a4}</p>
          </Answer>
          <Question2 onClick={toggle2}>
            <h2>{Writeups[0].FAQ.Q2.q}</h2>{" "}
            <StyledArrowUp toggledrawer2={toggledrawer2} size={30} />
          </Question2>
          <Answer toggledrawer2={toggledrawer2}>
            <li>{Writeups[0].FAQ.Q2.a1}</li>
            <li>{Writeups[0].FAQ.Q2.a2}</li>
            <li>{Writeups[0].FAQ.Q2.a3}</li>
            <li>{Writeups[0].FAQ.Q2.a4}</li>
          </Answer>
        </FaqC>
        {/* */}
        {/* <QuestionsAnsCon>
          <Question><h1>Q1</h1> <IoIosArrowUp size={40}/ ></Question>
          
          <Answer>A1</Answer>
        </QuestionsAnsCon>
        <QuestionsAnsCon>
          <Question><h1>Q1</h1></Question>
          
        </QuestionsAnsCon> */}
      </FaqContent>
    </FaqBody>
  );
};

export default Faq;

const FaqBody = styled.section`
  width: 100%;
  max-width: 100%;
  display: flex;
`;

const FaqContent = styled.div`
  width: 100%;
  display: flex;
  margin: 100px auto 0 auto;
  justify-content: center;
`;

const FaqC = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  > h1 {
    font-size: 50px;
    margin: 0px 0px 30px 0px;
    color: #7cb258;
  }
`;
const Question = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7cb258;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  > h2 {
    font-size: 20px;
    color: white;
    font-weight: 500;
  }
`;

const Question2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7cb258;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin-top: 50px;
  > h2 {
    font-size: 20px;
    color: white;
    font-weight: 500;
  }

  //
`;
const Answer = styled.div`
  display: ${(props) => (props.toggledrawer || props.toggledrawer2 ? "flex" : "none")};
  flex-direction: column;
  justify-content: start;
  padding: 5px 10px 5px 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px 40px 30px 40px;
  > p {
    margin: 10px 0px 0px 0px;
    font-size: 16px;
    font-family: "Mulish";
    font-weight: 700;
  }
  > p:nth-child(2) {
    margin: 0;
  }
  > p:nth-child(3) {
    margin: 20px 0px 0px 0px;
  }
  > p:nth-child(4) {
    margin: 0;
  }
  > li {
    list-style: decimal;
    font-weight: 700;
    font-size: 16px;
    font-family: "Mulish";
    padding: 0;
    margin-top: 15px;
  }
  > li:first-of-type {
    margin-top: 20px;
  }
`;

const StyledArrowUp = styled(IoIosArrowDown)`
  flex-shrink: 0;
  color: white;
  transform: rotate(${(props) => (props.toggledrawer || props.toggledrawer2 ? "180deg" : "0")});
  transition: transform 0.2s ease-in-out;
`;
