import React from "react";
import styled from "styled-components";
import Banner from "./NewsLetterComponent/Banner";
import background from "../assets/backgrounds/background.png";
import Pdf from "./NewsLetterComponent/Pdf";
import useDocumentTitle from "../Titlemanagement.jsx";

const NewsLetter = ({ Writeups }) => {
  useDocumentTitle("Sekolah Al-Ameerah Al-Hajjah Maryam | News Letter");

  return (
    <NewsLetterBody>
      <Banner Writeups={Writeups} />
      {/* <Pdf /> */}
    </NewsLetterBody>
  );
};

export default NewsLetter;

const NewsLetterBody = styled.div`
  min-height: calc(100vh);
  background-image: url(${background});
  background-size: contain;
`;
