import React, { useEffect } from "react";
import styled from "styled-components";
import { EMP } from "../../constant";
import OrgChart from "../../assets/sectionPicture/organizational-chart.png";
const OurTeam = ({ scroll }) => {
  return (
    <Teambody ref={scroll} id='ourTeam'>
      <MainTitle>Our Team</MainTitle>

      <TeamContainer>
        {EMP.BOD.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Board of Directors</h1> : <Hiddenh1>Board of Directors</Hiddenh1>}

            <Card>
              <Image src={EMP.BOD[index].imgUrl} alt={EMP.BOD[index].imgUrl} />
              <h3>{EMP.BOD[index].name}</h3>
            </Card>
          </Card2>
        ))}
      </TeamContainer>
      <TeamContainer>
        {EMP.principal.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Principal</h1> : <Hiddenh1>Principal</Hiddenh1>}

            <Card>
              <Image src={EMP.principal[index].imgUrl} alt={EMP.principal[index].imgUrl} />
              <h3>{EMP.principal[index].name}</h3>
              <p>{EMP.principal[index].qualification}</p>
            </Card>
          </Card2>
        ))}
      </TeamContainer>
      <TeamContainer>
        {EMP.Teachers.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Teachers</h1> : <Hiddenh1>Teachers</Hiddenh1>}

            <Card>
              <Image src={EMP.Teachers[index].imgUrl} alt={EMP.Teachers[index].imgUrl} />
              <h3>{EMP.Teachers[index].name}</h3>
              <p>{EMP.Teachers[index].qualification}</p>
              <p>{EMP.Teachers[index].specialty}</p>
              {/* <LineBreaker /> */}
              <p className='location'>{EMP.Teachers[index].specialtyLocation}</p>
              <p>{EMP.Teachers[index].specialty2}</p>
              {/* <LineBreaker /> */}
              <p className='location2'>{EMP.Teachers[index].specialty2Location}</p>
            </Card>
          </Card2>
        ))}
      </TeamContainer>
      <TeamContainer>
        {EMP.Tassistants.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Teaching Assistants</h1> : <Hiddenh1>Teaching Assistants</Hiddenh1>}

            <Card>
              <Image src={EMP.Tassistants[index].imgUrl} alt={EMP.Tassistants[index].imgUrl} />
              <h3>{EMP.Tassistants[index].name}</h3>
              <p>{EMP.Tassistants[index].specialty}</p>
              <p className='location'>{EMP.Tassistants[index].specialtyLocation}</p>
              <p>{EMP.Tassistants[index].qualification}</p>
            </Card>
          </Card2>
        ))}
      </TeamContainer>
      <TeamContainer>
        {EMP.SupportStaff.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Support Staff</h1> : <Hiddenh1>Support Staff</Hiddenh1>}

            <Card>
              <Image src={EMP.SupportStaff[index].imgUrl} />
              <h3>{EMP.SupportStaff[index].name}</h3>
              <p>{EMP.SupportStaff[index].qualification}</p>
            </Card>
          </Card2>
        ))}
        {EMP.Admin.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>Admin</h1> : <Hiddenh1>Admin</Hiddenh1>}

            <Card>
              <Image src={EMP.Admin[index].imgUrl} alt={EMP.Admin[index].imgUrl} />
              <h3>{EMP.Admin[index].name}</h3>
              <p>{EMP.Admin[index].qualification}</p>
            </Card>
          </Card2>
        ))}
        {EMP.FinanceOfficer.map((em, index) => (
          <Card2 key={index}>
            {index === 0 ? <h1>FinanceOfficer</h1> : <Hiddenh1>FinanceOfficer</Hiddenh1>}

            <Card>
              <Image
                src={EMP.FinanceOfficer[index].imgUrl}
                alt={EMP.FinanceOfficer[index].imgUrl}
              />
              <h3>{EMP.FinanceOfficer[index].name}</h3>
              <p>{EMP.FinanceOfficer[index].qualification}</p>
            </Card>
          </Card2>
        ))}
      </TeamContainer>
      {/* <OrganizationChart>
        <h1>Organizational Chart</h1>
        <img src={OrgChart} alt='Organizational Chart' />
      </OrganizationChart> */}
    </Teambody>
  );
};

export default OurTeam;

const Teambody = styled.section`
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 100%;
`;
const MainTitle = styled.h1`
  font-size: 50px;
  display: flex;
  justify-content: start;
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  color: #975ab6;
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  width: 280px;
  height: 500px;

  @media (max-width: 340px) {
    width: 100%;
  }
  > h3 {
    margin: 0;

    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    margin-top: 25px;
    font-size: 18px;
    font-family: "Mulish";
    font-weight: 800;
  }
  > p {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    margin-top: 25px;
    font-family: "Mulish";
    font-weight: 500;
    font-size: 14px;
  }
  .location {
    margin: 0;
  }
  .location2 {
    margin: 0;
  }
`;

// const LineBreaker = styled.div`
//   display: flex;
//   width: 100%;
//   background-color: black;
//   height: 2px;
// `;

const Image = styled.img`
  margin-top: 20px;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center center;
  scale: 1;
`;

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px 0;
  > h1 {
    color: #975ab6;
    font-size: 40px;
  }
`;

const Hiddenh1 = styled.h1`
  color: transparent;
  visibility: hidden;
`;

const OrganizationChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  h1 {
    margin: 0;
    font-size: 50px;
    color: #975ab6;
    margin-top: 100px;
  }

  img {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
  }
`;
