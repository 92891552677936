import React from "react";
import styled from "styled-components";
import Logos from "./FooterComponent/Logos";
import Links from "./FooterComponent/Links";
import ReactDOMServer from "react-dom/server";
import footerbackground from "../assets/backgrounds/footer2.png";
import footerbackground2 from "../assets/backgrounds/footer.png";

const Footer = ({ Writeups, routes, activeLink, handleNavRouteClick, setShowTabBar }) => {
  return (
    <>
      {/* <LogoContainer>
        <Logos />
      </LogoContainer> */}

      <FooterContainer>
        {/* <Logos /> */}
        <Links
          Writeups={Writeups}
          routes={routes}
          activeLink={activeLink}
          handleNavRouteClick={handleNavRouteClick}
          setShowTabBar={setShowTabBar}
        />
      </FooterContainer>
    </>
  );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${footerbackground2});
  @media (max-width: 680px) {
    background-image: url(${footerbackground2});
  }
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* adjust as needed */
  min-height: 60vh;
  width: 100%;
  max-width: 100%;
`;
