import React from "react";
import styled from "styled-components";
import BannerImage from "../../assets/backgrounds/banner-main.png";
const Banner = ({ Writeups }) => {
  return (
    <>
      <BannerBody>
        <BodyContainer>
          <h1>{Writeups[0].Banner.admission.title}</h1>
          <h3>{Writeups[0].Banner.admission.subtitle}</h3>
        </BodyContainer>
      </BannerBody>
    </>
  );
};

export default Banner;

const BannerBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${BannerImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  height: 100%;
  @media (max-width: 600px) {
    background-size: cover;
  }
  > h1 {
    color: #f5f5f5;
    margin: 0;
    font-size: 50px;

    @media (max-width: 475px) {
      text-align: center;
    }
  }
  > h3 {
    color: #e5d6eb;
    font-size: 30px;
    text-align: center;
  }
`;
