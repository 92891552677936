import React, { useEffect } from "react";
import styled from "styled-components";

import supportServices from "../../assets/sectionPicture/support-services.png";
const Services = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <ServicesBody ref={scroll} id='supportServices'>
      <ServicesCon>
        <img src={supportServices} alt='Support Services' />
        <WriteupsBod>
          <h1>{Writeups[0].SupportServices.title}</h1>
          <h3>{Writeups[0].SupportServices.subtitle}</h3>
          <p>{Writeups[0].SupportServices.p1}</p>
          <p>{Writeups[0].SupportServices.p2}</p>
        </WriteupsBod>
      </ServicesCon>
    </ServicesBody>
  );
};

export default Services;

const ServicesBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5px;
`;

const ServicesCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  width: 100%;
  > img {
    width: 500px;
    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-left: auto;
    }
  }
`;

const WriteupsBod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 9%;
  @media (max-width: 820px) {
    margin-left: 0;
  }
  > h1 {
    font-size: 50px;
    color: #55833e;
    margin: 0;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }
  > h3 {
    font-weight: 800;
    font-family: "Mulish";
    margin: 0;
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
  }
  > p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    margin-left: 30px;
    margin-top: 15px;
    margin-right: 30px;
    text-align: justify;
  }
  > p:first-of-type {
    margin-top: 15px;
  }
`;
