import React, { useEffect } from "react";
import styled from "styled-components";
import dummy from "../../assets/sectionPicture/dummy2.png";
import leaf from "../../assets/backgrounds/leaf-background-purple.png";
const Founders = ({ scroll }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("ourschool");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <FoundersBody ref={scroll} id="foundersWelcome">
      <FoundersCon>
        <Doc>
          <h1>Founder's Welcome:</h1>
          <Writeups>
            <img src={dummy} />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac
              tellus a augue sollicitudin faucibus non vitae tortor. Etiam
              vestibulum venenatis ullamcorper. In sagittis mollis orci, et
              vestibulum sem efficitur at. Curabitur sed lacus porta, viverra
              nisl vel, efficitur sem. Proin in lorem sit amet leo pulvinar
              rhoncus at sagittis nisi. Proin tortor ante, feugiat vitae arcu
              in, molestie mattis lacus. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Proin a
              convallis sem. Duis lobortis placerat enim posuere molestie.
              Praesent quis euismod mi. In hac habitasse platea dictumst. Nam
              dictum elit vel scelerisque fermentum. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Phasellus placerat est massa, vitae maximus arcu
              scelerisque eu.
            </p>
          </Writeups>
        </Doc>
      </FoundersCon>
    </FoundersBody>
  );
};

export default Founders;

const FoundersBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
`;

const FoundersCon = styled.div`
  display: flex;
  margin: 100px auto 0 auto;
  width: 80%;
  padding: 20px;
  background-color: #f5f5f5;
  background-image: url(${leaf});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 30px;
  justify-content: start;
  flex-direction: column;
`;

const Doc = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  > h1 {
    font-size: 40px;
    color: #975ab6;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 80px;
    @media (max-width: 820px) {
      margin: 0 auto;
      text-align: center;
    }
  }
`;

const Writeups = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
  > img {
    width: 30%;
    @media (max-width: 820px) {
      width: 50%;
    }
  }
  > p {
    font-size: 20px;
    font-family: "Mulish";
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
  }
`;
