import React, { useEffect } from "react";
import styled from "styled-components";
import types from "../../assets/sectionPicture/types-of-learners.png";
const Academics = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <AcademicBody ref={scroll} id='academics'>
      <AcademicCon>
        <WriteupsBod>
          <h1>{Writeups[0].Academics.title}</h1>
          <p>{Writeups[0].Academics.p1}</p>
          <img src={types} alt='typesDiagram' />
          <p>{Writeups[0].Academics.p2}</p>
        </WriteupsBod>
      </AcademicCon>
    </AcademicBody>
  );
};

export default Academics;

const AcademicBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
`;

const AcademicCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  flex-wrap: wrap;
`;

const WriteupsBod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  > h1 {
    font-size: 50px;
    color: #975ab6;
    margin-left: 12%;
    margin-right: 12%;
    margin-top: 0;
    margin-bottom: 10px;
    /* margin-left: 40px;
    margin-right: 40px; */
  }
  > p {
    font-family: "Mulish";
    font-weight: 600;
    font-size: 20px;
    margin-left: 12%;
    margin-right: 12%;
    /* margin: 0; */
    /* margin-left: 30px; */
    margin-top: 15px;
    /* margin-right: 30px; */
    text-align: justify;
  }
  > p:first-of-type {
    margin-top: 25px;
    font-weight: 600;
  }
  > img {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 90%;
    }
    @media (max-width: 320px) {
      width: 90%;
      height: 50%;
    }
  }
`;
