import React, { useState, useRef } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled, { createGlobalStyle } from "styled-components";
import { routes, routesMalay } from "./constant";
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from "react-router-dom";
import { Writeups, WriteupsMalay } from "./constant";
import Mulish from "./assets/fonts/Mulish.ttf";
import Fredoka from "./assets/fonts/Fredoka-VariableFont_wdth,wght.ttf";
import Admission from "./screens/Admission";
import OurSchool from "./screens/OurSchool";
import Learning from "./screens/Learning";
import NewsLetter from "./screens/NewsLetter";
import ContactUs from "./screens/ContactUs";
import Calendar from "./screens/Calendar";

function App() {
    const navigate = useNavigate();
    //for routing if user to directly navigate to specific page
    const mapPathToRouteName = (path) => {
        switch (path) {
            case "":
                return "Admissions"; // For the root path
            case "our-school":
                return "Our School";
            case "news-letter":
                return "News Letter";
            case "learning":
                return "Learning";
            case "contact-us":
                return "Contact Us";
            // Add other cases as needed
            default:
                return path.charAt(0).toUpperCase() + path.slice(1); // Capitalize the first letter by default
        }
    };
    const linkMap = {
        Admissions: "Pendaftaran",
        "Our School": "Sekolah Kami",
        Learning: "Pembelajaran",
        "News Letter": "Surat Berita",
        "Contact Us": "Hubungi Kami",
    };
    const location = useLocation();
    console.log("Current Location:", location.pathname);
    const currentPath = mapPathToRouteName(location.pathname.slice(1));
    console.log("Current Path after slice:", currentPath);
    const defaultActiveLink = sessionStorage.getItem("activeLink") || currentPath || routes[0].name;
    console.log("Default Active Link:", defaultActiveLink);
    const [activeLink, setActiveLink] = useState(defaultActiveLink);
    const [showTabBar, setShowTabBar] = useState(true);
    const [lang, setLang] = useState(false);
    const [WriteupsGlobal, setWriteupsGlobal] = useState(Writeups);
    const [routesGlobal, setRoutes] = useState(routes);
    React.useEffect(() => {
        setActiveLink(currentPath);
        sessionStorage.setItem("activeLink", currentPath);
    }, [currentPath]);

    //Admission Nav
    const appProcess = useState(null); //TODO
    const adFees = useState(null);
    const schoolCalendar = useState(null);
    const FAQ = useState(null);
    const News = useState(null);

    //OurSchool Nav
    const aboutUs = useState(null); //TODO
    const fWelcome = useState(null);
    const visionmission = useState(null);
    const team = useState(null);
    const schoolfacilities = useState(null);

    //Learning Nav
    const academics = useState(null); //TODO
    const curriculums = useState(null);
    const earlyYears = useState(null);
    const primarySchool = useState(null);
    const RA = useState(null);
    const assessments = useState(null);
    const supportServices = useState(null);

    const scrollToBanner = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleNavRouteClick = (routeName) => {
        const hasSubRoutes = routesGlobal.find((r) => r.name === routeName)?.subRoutes?.length > 0;

        setShowTabBar(false);

        setTimeout(() => {
            setActiveLink(routeName);
            sessionStorage.setItem("activeLink", routeName);
            if (hasSubRoutes) {
                setShowTabBar(true);
            }
        }, 100);
    };

    const navigateToCalendar = () => {
        navigate("/calendar");
    };

    const [DrawerNavOpen, setDrawerNavOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerNavOpen(!DrawerNavOpen);
    };

    const getMappedRouteName = (name, isToMalay) => {
        if (isToMalay) {
            return linkMap[name] || name;
        } else {
            return Object.keys(linkMap).find((key) => linkMap[key] === name) || name;
        }
    };

    const toggleLanguage = () => {
        const newLang = !lang;
        const newRoutes = newLang ? routesMalay : routes;
        const newWriteups = newLang ? WriteupsMalay : Writeups;

        const newActiveLink = getMappedRouteName(activeLink, newLang);

        setActiveLink(newActiveLink);
        sessionStorage.setItem("activeLink", newActiveLink);

        setLang(newLang);
        setWriteupsGlobal(newWriteups);
        setRoutes(newRoutes);
    };

    return (
        <div>
            <GlobalStyle />

            <Header
                routes={routesGlobal}
                activeLink={activeLink}
                handleNavRouteClick={handleNavRouteClick}
                showTabBar={showTabBar}
                toggleDrawer={toggleDrawer}
                isopen={DrawerNavOpen}
                scrolltosection={scrollToBanner}
                appProcess={appProcess}
                adFees={adFees}
                schoolCalendar={schoolCalendar}
                FAQ={FAQ}
                news={News}
                aboutUs={aboutUs}
                fWelcome={fWelcome}
                visionmission={visionmission}
                team={team}
                schoolfacilities={schoolfacilities}
                academics={academics}
                curriculums={curriculums}
                earlyYears={earlyYears}
                primarySchool={primarySchool}
                RA={RA}
                assessments={assessments}
                supportServices={supportServices}
                toggleLanguage={toggleLanguage}
            />
            <Routes>
                <Route
                    path='/'
                    element={
                        <Admission
                            appProcess={appProcess}
                            adFees={adFees}
                            schoolCalendar={schoolCalendar}
                            FAQ={FAQ}
                            news={News}
                            Writeups={WriteupsGlobal}
                            nav={navigateToCalendar}
                        />
                    }
                />
                <Route
                    path='/our-school'
                    element={
                        <OurSchool
                            aboutUs={aboutUs}
                            fWelcome={fWelcome}
                            visionmission={visionmission}
                            team={team}
                            schoolfacilities={schoolfacilities}
                            Writeups={WriteupsGlobal}
                        />
                    }
                />
                <Route
                    path='/learning'
                    element={
                        <Learning
                            academics={academics}
                            curriculums={curriculums}
                            earlyYears={earlyYears}
                            primarySchool={primarySchool}
                            RA={RA}
                            assessments={assessments}
                            supportServices={supportServices}
                            Writeups={WriteupsGlobal}
                        />
                    }
                />
                <Route path='/news-letter' element={<NewsLetter Writeups={WriteupsGlobal} />} />
                <Route path='/contact-us' element={<ContactUs Writeups={WriteupsGlobal} />} />
                <Route path='/calendar' element={<Calendar Writeups={WriteupsGlobal} />} />
            </Routes>
            <Footer
                Writeups={WriteupsGlobal}
                routes={routesGlobal}
                activeLink={activeLink}
                handleNavRouteClick={handleNavRouteClick}
                setShowTabBar={setShowTabBar}
            />
        </div>
    );
}

export default App;

const GlobalStyle = createGlobalStyle`
@import "~leaflet/dist/leaflet.css";

@font-face {
  font-family: 'Mulish';
  src: url(${Mulish}) format('truetype');
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
}

@font-face {
  font-family: 'Fredoka';
  src: url(${Fredoka}) format('truetype');

}


body {
    margin: 0;
    padding: 0;
    font-family: "Fredoka";
  }

h1 {

}

h2 {
  font-family: "Fredoka";
  font-weight: 600;
}
  /* Other global styles go here */

`;
