//bod

//principal
import picture from "./assets/StaffMember/Ms-Nurus.jpeg";

//teacher
import jHamid from "./assets/StaffMember/Jasmina-Hamid.jpeg";
import adiman from "./assets/StaffMember/Teacher-Adiman.jpeg";
import ain from "./assets/StaffMember/Teacher-Ain.jpeg";
import dksyahirah from "./assets/StaffMember/Teacher-Dksyahirah.jpeg";
import eyna from "./assets/StaffMember/Teacher-Eyna.jpeg";
import ezza from "./assets/StaffMember/Teacher-Ezza.jpeg";
import mark from "./assets/StaffMember/Teacher-Nurse-Mark.jpeg";
import nadirah from "./assets/StaffMember/Teacher-Nurul-Nadirah.jpeg";
import wadiah from "./assets/StaffMember/Teacher-Wadiah.jpeg";
import dummy from "./assets/StaffMember/dummyProfile.png";

//facilities picture
import classroom from "./assets/facilities/classroom.png";
import library from "./assets/facilities/Library.png";
import occupational from "./assets/facilities/Occupational-Therapy-Room.png";
import physio from "./assets/facilities/Physio-Therapy-Room.png";
import reading from "./assets/facilities/reading-corner.png";
import safezones from "./assets/facilities/safe-zones.png";
import sensory from "./assets/facilities/sensory-room.png";
import speech from "./assets/facilities/Speech-language-therapy-room.png";
import surau from "./assets/facilities/Surau.png";
import swimmingpool from "./assets/facilities/swimming-pool.png";

export const routes = [
	{
		name: "Admissions",
		link: "/",
		subRoutes: [
			{
				name: "Application Process",
				link: "/?admission=applicationSection",
			},
			{
				name: "Admission Fees",
				link: "/?admission=feesSection",
			},
			{
				name: "School Calendar",
				link: "/?admission=calendarSection",
			},
			{
				name: "FAQ",
				link: "/?admission=faqSection",
			},
			{
				name: "In The News",
				link: "/?admission=newsSection",
			},
		],
	},
	{
		name: "Our School",
		link: "/our-school",
		subRoutes: [
			{
				name: "About Us",
				link: "/our-school/?ourschool=aboutUs",
			},
			// {
			//   name: "Founder's welcome",
			//   link: "/our-school/?ourschool=foundersWelcome",
			// },
			{
				name: "Vision & Mission",
				link: "/our-school/?ourschool=visionmission",
			},
			{
				name: "Our Team",
				link: "/our-school/?ourschool=ourTeam",
			},
			{
				name: "School Facilities",
				link: "/our-school/?ourschool=schoolFacilities",
			},
		],
	},
	{
		name: "Learning",
		link: "/learning",
		subRoutes: [
			{
				name: "Academics",
				link: "/learning/?learn=academics",
			},
			{
				name: "Our Curriculum",
				link: "/learning/?learn=curriculum",
			},
			{
				name: "Early Years",
				link: "/learning/?learn=earlyYears",
			},
			{
				name: "Primary & Middle Years",
				link: "/learning/?learn=primarySchool",
			},
			{
				name: "Religious Lessons",
				link: "/learning/?learn=RA",
			},
			{
				name: "Assesments",
				link: "/learning/?learn=assessments",
			},
			{
				name: "Support Services",
				link: "/learning/?learn=supportServices",
			},
		],
	},
	{
		name: "News Letter",
		link: "/news-letter",
	},
	{
		name: "Contact Us",
		link: "/contact-us",
	},
];
export const routesMalay = [
	{
		name: "Pendaftaran",
		link: "/",
		subRoutes: [
			{
				name: "Proses Permohonan",
				link: "/?admission=applicationSection",
			},
			{
				name: "Yuran Kemasukan",
				link: "/?admission=feesSection",
			},
			{
				name: "Kalendar Sekolah",
				link: "/?admission=calendarSection",
			},
			{
				name: "Soalan Lazim",
				link: "/?admission=faqSection",
			},
			{
				name: "Berita",
				link: "/?admission=newsSection",
			},
		],
	},
	{
		name: "Sekolah Kami",
		link: "/our-school",
		subRoutes: [
			{
				name: "Tentang Kami",
				link: "/our-school/?ourschool=aboutUs",
			},
			// Note: "Founder's welcome" could be translated as "Selamat Datang dari Pengasas"
			{
				name: "Visi & Misi",
				link: "/our-school/?ourschool=visionmission",
			},
			{
				name: "Team Kami",
				link: "/our-school/?ourschool=ourTeam",
			},
			{
				name: "Kemudahan Sekolah",
				link: "/our-school/?ourschool=schoolFacilities",
			},
		],
	},
	{
		name: "Pembelajaran",
		link: "/learning",
		subRoutes: [
			{
				name: "Akademik",
				link: "/learning/?learn=academics",
			},
			{
				name: "Kurikulum Kami",
				link: "/learning/?learn=curriculum",
			},
			{
				name: "Tahun Awal",
				link: "/learning/?learn=earlyYears",
			},
			{
				name: "Tahun Rendah & Pertengahan",
				link: "/learning/?learn=primarySchool",
			},
			{
				name: "Pelajaran Agama",
				link: "/learning/?learn=RA",
			},
			{
				name: "Penilaian",
				link: "/learning/?learn=assessments",
			},
			{
				name: "Perkhidmatan Sokongan",
				link: "/learning/?learn=supportServices",
			},
		],
	},
	{
		name: "Surat Berita",
		link: "/news-letter",
	},
	{
		name: "Hubungi Kami",
		link: "/contact-us",
	},
];

export const contact = [
	{
		address: {
			title: "Address: 41 Sunset Way, #01-01 Clementi Arcade, Singapore (S597071)",
		},
		phone: {
			title: "Phone: +65 6466-4475",
		},
		email: {
			title: "Email: admission@iis.edu.sg",
		},
		commitee: {
			title:
				"IIS is registered with the Commitee for Private Education(CPE), which is part of SkillSFuture Singapore(SSG)",
		},
	},
];

export const Writeups = [
	{
		Banner: {
			admission: {
				title: "Special Needs School",
				subtitle: "Unlocking Potential, Empowering Differences",
			},
			OurSchool: {
				title: "Our School",
			},
			Learning: {
				title: "Learning",
			},
			NewsLetter: {
				title: "News Letter",
			},
			ContactUs: {
				title: "Contact Us",
			},
		},
		appProcess: {
			title: "Application Process",
			subTitle:
				"Registering at Sekolah Al-Ameerah Al-Hajjah Maryam: A Guide for Prospective Students",
			content: {
				p1: "To enroll at Sekolah Al-Ameerah Al-Hajjah Maryam, potential students must complete a Registration Form. This form is available at our Education Unit Office, Pusat Ehsan Al-Ameerah Al-Hajjah Maryam, New Building, Level 1, Education Unit Office, Kg Bengkurong.",
				p2: "We accept applications throughout the academic year, and each submission is evaluated in the order it is received. Before registering, applications must provide the necessary documents to ensure legibility",
			},
		},
		documents: {
			title: "Enrolment Process",
			subtitle: "Required Documents",
			p1: "Diagnostic report from Child Development Center (CD), Kiarong",
			p2: "School Report by SENA teacher (Jabatan Pendidikan Khas) or SENA Teacher Private School",
			note1: "Please Bring these documents with you when inquiring about our Registration Form.",
			note2: "Please Contact our Admission Team for further details.",
		},
		addFees: {
			title: "Admission Fees",
			subTitle: "",
			content: {
				p1: "There will be Admission Fee of $50",
				p2: "This will be paid after the team has approvedd that the Registration form is completed thoroughly, including all the mandated Documents.",
				p3: "Please Note that this fee is Non-Refundable.",
			},
		},
		cal: {
			title: "School Calendar",
			p1: "There are currently no upcoming events",
			button: "View school calendar",
		},
		FAQ: {
			title: "Frequently Asked Questions",
			Q1: {
				q: "1. What are the schooling hours like?",
				a1: "Monday - Fridays",
				a2: "2 Sessions (Parents are able to pick, morning sessions or afternoon sessions",
				a3: "Morning Sessions: 8.30am - 11:30am",
				a4: "Afternoon Sessions: 1.30pm - 4:00pm",
			},
			Q2: {
				q: "2. What are the legibility requirements to enroll at Sekolah Al-Ameerah Al-Hajjah Maryam",
				a1: "Open to Citizens of Brunei Darussalam, Permanent Resident and Foreigner",
				a2: "Must have a CDC Report that states your child's learning needs and diagnosis",
				a3: "Must have Special Education Report provided by Special Education Department (Jabatan pendidikan Khas) or Sena Teacher of Government School / Private School (Report should be dated not more than 3 years)",
				a4: "Priority is given to students identified as Priority Level 4 & 5 stated in students Special Education Report by Jabatan Pendidikan Khas",
			},
		},
		news: {
			title: "In the News",
			p1: "There are currently no available news",
		},
		About: {
			title: "About Us",
			description:
				"Sekolah Al-Ameerah Al-Hajjah Maryam is a private inclusive special needs school that provides services for Special Needs Students.Sekolah Al-Ameerah Al-Hajjah Maryam is under the care of Pusat Ehsan Al-Ameerah Al-Hajjah Maryam, a non-profit, non-governement charitable organization. Pusat Ehsan Al-Ameerah Al-Hajjah Maryam was established in the year 2000, and it has been devoted in providing educational programs, rehabilitation services and training for individuals with special needs. Over the years, Pusat Ehsan has recognize the need for a Special Needs School to complement government efforts and addressing the gaps in the currenct education ecosystem",
			description2:
				"In 2021, Pusat Ehsan was given the opportunity and endorsement by Ministry of Education, Jabatan Pendididkan Swasta to develop a school for special needs students in Brunei Darussalam. Through the years of development, Sekolah Al-Ameerah Al-Hajjah Maryam has been established in 2023, whereby the ministry of education recognises the school as a school for special needs. ",
			description3:
				"Sekolah Al-Ameerah Al-Hajjah Maryam will be working closely with Pusat Ehsan Al-Ameerah Al-Hajjah Maryam to ensure that we provide a holistic approach when caring for students development. Sekolah Al-Ameerah Al-Hajjah Maryam is set to provide educational services, while Pusat Ehsan Al-Ameerah Al-hajjah Maryam will offer students at the school various rehabilitation services, including physiotherapy, occupational therapy, speech and language therapy, and psychology.",
		},
		vs: {
			title1: "Vision",
			p1: "Enabling Our Special Needs Community Towards a Meaningful Life in Society",
			title2: "Mission",
			p2: "To educate, assist and care for people with Special Needs to achieve their full potential",
			title3: "School Moto",
			p3: "Unlocking Potential, Empowering Differences!",
		},
		sFacilities: {
			title: "School Facilities",
			p1: "Our Facilities are fully equipped with inclusive access for all learners. The infrastructure of our school consists of 6 classrooms in the newly built school for the Primary Students, an inclusive playground, picnic garden and the Pavilion where students have their snack and breaktime.",
			p2: "1 Early Years Classroom allocated in the Rehabilitation Building, Level 1 with a supporting library and outdoor play area. A Therapy Garden, Sensory Room and an inclusive swimming pool.",
		},

		Academics: {
			title: "Academics",
			p1: "Every student is unique and individuals with different learning styles and strengths.",
			p2: "Ensuring that we take into consideration of all the different learning styles, students will then be able to achieve their fullest potential, where ever that maybe.",
		},
		OurCurriculum: {
			title: "Our Curriculum",
			vision: "Enabling Our Special Needs Community Towards a Meaningful Life in Society",
			mission:
				"To educate, assist and care for people with Special Needs to achieve their full potential",
			guidingPrinciple: [
				"Excellence",
				"Inclusive",
				"Student Centred",
				"Holistic",
				"Outcome Orientated",
				"Advocacy Driven",
				"Collaborative Efforts",
			],
			theCommunityTobe: [
				"Understand Self and the world around them",
				"Value diversity",
				"Be confident",
				"Be resilient",
				"Curious",
				"Manage Self in different situations",
			],
			p1: "Sekolah Al-Ameerah Al-Hajjah Maryam has identified four areas where students require support that align with the desired outcomes of Brunei Darussalam's 21st-century National Curriculum.",
			p2: [
				"Self-Aware - Understand one’s self and the world around them.",
				"Adaptable - Manage self in different situations",
				"Diverse - Value diversity and relate with others effectively",
				"Confident, resilient, and curious. ",
			],
			p3: "We are strong believers in the concept of Life-long learning. The idea that learning should be a continuous process throughout one's life, not just limited to formal education in childhood and young adulthood. It involves the acquisition of new knowledge, skills, and abilities that can be applied to both personal and professional development.",
			p4: "Life-long learning is essential for everyone, including special needs students. Continuous learning can benefit special needs students in several ways, including improved cognitive abilities, enhanced social skills, increased independence, and better career opportunities. It helps strengthen memory, attention, and focus, while also promoting social interaction and collaboration. Additionally, it can lead to greater self-sufficiency and confidence, as well as better career prospects.",
		},
		EarlyYears: {
			title: "Early Years",
			subtitle: "3 - 5 Years Old",
			p1: "Early Years and Primary School Programs Catering to Different Learners. Sekolah Al-Ameerah Al-Hajjah Maryam believes in Early Intervention, acknowledging that a child's developmental journey begins at infancy. The Early Years Program targets 3-5 years-old, promoting basic foundational knowledge and learning readiness for a smoother transition into mainstream education. The program is designed to equip students with academic and independent life skills, providing a holistic approach to learning. Specifically, the Early Years Program caters to differently-abled learners, ensuring that they receive quality early childhood care and education.",
			primeareas: {
				title: "3 Prime Areas",
				p1: "We use The Early Years Foundation Stage Framework which focuses on three prime areas, which are essential for the foundtation of children's learning:",
				p2: [
					"Personal, Social and Emotional Development, which includes self-confidence & self-awareness, managing feelings & behavior, and making relationships.",
					"Communication and Language, which includes listening and attention, understanding, and speaking.",
					"Physical Development, which includes moving and handling, and health and self-care.",
				],
			},
			areasofLearning: {
				title: "Areas Of Learning",
				p1: "From the prime areas, we build children's Specific Areas of Learning, which include:",
				p2: [
					"English; reading and writing.",
					"Mathematics; working with numbers, shape, space, and measures.",
					"Understanding the World; people and communities, the world, and technology.",
					"Expressive Arts and Design; exploring and using media and materials, and being imaginative.",
				],
			},
			p4: "We believe that providing a happy, secure and confident space for students to learn will build their confidence and enhance their love for learning which better provides them opportunities in achieving their potential.",
		},
		PrimarySchool: {
			title: "Primary & Middle Years",
			subtitle: "6 - 13 Years old",
			p1: "The Primary and Middle years program cater to students aged 6 - 13 years old. The program aims to deliver a student-centred, individualized, and differentiated approach to education. The program focuses on basic functional academic subjects such as Literacy (English and Malay), Numeracy, and Adaptive Living Skills. Additionally, non-academic subjects such as Character Building and Social and Emotional awareness are incorporated to help students with social skills.",
			functionalAcademics: {
				title: "Functional Academics",
				p1: ["Literacy", "Maths", "Ugama", "Social Science", "Arts"],
			},
			tppse: {
				title: "The Physical, Personal, Social & Emotional Learning",
				p1: ["PE", "Personal Care", "Social & Emotional", "Activities of Daily Living"],
			},
			p2: "Our Primary Curriculum is supported by the Special Needs Framework, published in November 2022 by Jabatan Pendidikan Khas, Ministry of Education. Streamlining our current curriculum has been developed internally by a specialist consultant from Singapore, Dr Vincent Yeo and the Pusat Ehsan Team in 2015 with the newly published Jabatan Pendidikan Sekolah Rendah Special Needs Curriculum, we have tailored our curriculum to meet students individualised goals.",
		},
		Religious: {
			title: "Religious Affairs Ugama Lessons",
			p1: "At Sekolah Al-Ameerah Al-Hajjah Maryam, we believe in holistic approach to education, which includes teaching students about faith. We currently have three Ugama teachers seconded by the Ministry of Religious Affairs to provide religous instruction to our students. They learn the basics of Islam, including the five daily prayers, reading the Al-Quran, and Amali Agama.",
		},
		AssessmentsReporting: {
			title: "Assessments & Reporting",
			p1: "At our institution, we prioritize teaching the children rather than only following the curriculum. To achieve this, we use assessments and data not only to measure progress but also to inform our teaching. Formal and informal assessments are conducted regularly to gauge the children's learning. Daily assessments, observations, reviews of marked work, and Q&A sessions allow our teachers to adjust their teaching plans to better suit the needs of the class. Formal assessments provide an overview of the child's progress.",
			p2: "These reports will be provided through Parent Teacher Meetings which are held termly.",
		},
		SupportServices: {
			title: "Support Services",
			subtitle: "The Rehabilitation Unit",
			p1: "Sekolah Al-Ameerah Al-Hajjah Maryam is working closely with the Rehabilitation Unit under the care of Pusat Ehsan Al-Ameerah Al-Hajjah Maryam to ensure a holistic approach; we believe that therapeutic interventions are crucial for individuals who need help achieving their highest level of functionality and independence, regardless of movement limitations, learning disabilities, or social skill deficits. This belief drives our mission to provide these interventions to those in need.",
			p2: "Our Rehabilitation Unit, consists of Occupational Therapy, Physiotherapy, Speech and Language Therapy and Psychology.",
			Physiotherapy: {
				title: "Physiotherapy",
				p1: "A profession that manages health and body functions. It provides strategic plan to implement conservative approaches in minimising limiation of function and to heighten clients' independence necessary to fulfill their daily activities and needs.",
			},
			occupational: {
				title: "Occupational Therapy",
				p1: "Supports clients in different areas in their everyday activities (self-care, leisure activities and being productive) and be able to become independent in their everyday life.",
			},
			speech: {
				title: "Speech & Language Therapy",
				p1: "It is a communication science of speech, language, fluency, feeding and swallowing. along with non-verbal and verbal means of communication",
			},
			Psychology: {
				title: "Psychology",
				p1: "Psychology's Role in Understanding Neurodiversity and Fostering inclusion. Psychology is all around us, shaping our experiences and the lessons we learn in life. It offers a valuable lens through which to examine why individuals behave the way they do, and how their environment can affect them. THis is especially important when it comes to the special needs community. Unfortunately, many people are unaware of the characteristics of neurodivergent individuals, such as those with Autism or Down Syndrome. By taking the time to understand their unique needsm we can create an inclusive environment that fosters their growth.",
			},
		},
		ContactUs: {
			title1: "School Administration",
			title2: "School Principal",
			title3: "School Email",
			title4: "General Office",
			title5: "Email",
			title6: "Website",
			title7: "Rehabilitation Unit",
			title8: "Administration",
			title9: "Finance Office",
		},
		career: {
			title: "Career",
			p1: "Interested to join the team? Leave us your details and your CV. We will contact you as soon as we can!",
			label1: "Full Name*",
			label2: "Email*",
			label3: "Phone No. *",
			label4: "Upload Curriculum Vitae*",
			label5: "Choose file to upload",
		},
		footer: {
			quick: "QuickLinks",
			social: "Social",
		},
		calendar: {
			title: "School term calendar"
		}
	},
];
export const WriteupsMalay = [
	{
		Banner: {
			admission: {
				title: "Sekolah Istimewa",
				subtitle: "Membuka potensi, memperkasakan perbezaan",
			},
			OurSchool: {
				title: "Sekolah Kami",
			},
			Learning: {
				title: "Pembelajaran",
			},
			NewsLetter: {
				title: "Surat Berita",
			},
			ContactUs: {
				title: "Hubungi Kami",
			},
		},
		appProcess: {
			title: "Proses permohonan",
			subTitle: "Panduan untuk mendaftar di Sekolah Al-Ameerah Al-Hajjah Maryam bagi bakal pelajar",
			title2: "Proses pendaftaran",
			content: {
				p1: "Untuk mendaftar di Sekolah Al-Ameerah Al-Hajjah Maryam, bakal pelajar mesti melengkapkan Borang Pendaftaran. Borang pendaftaran boleh didapati di Pejabat Education Unit kami, Pusat Ehsan Al-Ameerah Al-Hajjah Maryam, New Building, Level 1, Education Unit Office, Kg Bengkurong.",
				p2: "Kami menerima permohonan sepanjang tahun akademik, dan setiap penyerahan dinilai mengikut susunan yang diterima. Sebelum mendaftar, permohonan mesti menyediakan dokumen yang diperlukan untuk diproses",
			},
		},
		documents: {
			title: "Proses pendaftaran",
			subtitle: "Dokumen yang diperlukan:",
			p1: "Laporan diagnostik dari Child Development Center (CDC), Kiarong",
			p2: "Laporan Sekolah daripada guru SENA (Jabatan Pendidikan Khas) atau Sekolah Swasta Guru SENA",
			note1: "Sila Bawa dokumen ini bersama anda apabila bertanya Borang Pendaftaran.",
			note2: "Sila Hubungi Admission Team kami untuk butiran lanjut.",
		},
		addFees: {
			title: "Yuran Kemasukan",
			subTitle: "",
			content: {
				p1: "Yuran Kemasukan adalah sebanyak $50",
				p2: "This will be paid after the team has approvedd that the Registration form is completed thoroughly, including all the mandated Documents. Yuran hendaklah dibayar selepas borang Pendaftaran yang lengkap termasuk semua Dokumen yang diwajibkan, telah diluluskan.",
				p3: "Sila ambil maklum bahawa yuran tersebut adalah Non-Refundable.",
			},
		},
		cal: {
			title: "Kalendar Sekolah",
			p1: "Tidak ada acara pada waktu ini",
			button: "Lihat Kalendar Sekolah",
		},
		FAQ: {
			title: "Soalan lazim",
			Q1: {
				q: "1. Bagaimana waktu persekolahan?",
				a1: "Isnin - Juma'at",
				a2: "2 Sesi (Ibu bapa boleh memilih sesi persekolahan di antara sesi pagi atau sesi petang)",
				a3: "Sesi pagi: 8.30 pagi - 11:30 pagi",
				a4: "Sesi petang: 1.30 petang - 4:00 petang",
			},
			Q2: {
				q: "2. Apakah syarat-syarat untuk mendaftar di Sekolah Al-Ameerah Al-Hajjah Maryam?",
				a1: "Terbuka kepada Warga Negara Brunei Darussalam, Penduduk Tetap dan Warga Asing",
				a2: "Mempunyai Laporan CDC yang menyatakan keperluan bantuan pembelajaran dan diagnosis anak anda",
				a3: "Mempunyai Laporan Pendidikan Khas yang disediakan oleh Special Education Department (Jabatan Pendidikan Khas) atau Guru SENA dari Sekolah Kerajaan / Sekolah Swasta (Laporan hendaklah tidak melebihi 3 tahun)",
				a4: "Keutamaan diberikan kepada pelajar yang dikenal pasti sebagai Keutamaan Tahap 4 & 5 yang dinyatakan dalam Special Education Report pelajar dari Jabatan Pendidikan Khas",
			},
		},
		news: {
			title: "Berita terkini",
			p1: "Tidak ada berita pada masa ini",
		},
		About: {
			title: "Tentang Kami",
			description:
				"Sekolah Al-Ameerah Al-Hajjah Maryam ialah sebuah sekolah berkeperluan khas inklusif yang menyediakan perkhidmatan untuk Murid Berkeperluan Khas. Sekolah Al-Ameerah Al-Hajjah Maryam di bawah jagaan Pusat Ehsan Al-Ameerah Al-Hajjah Maryam, non-profit, non-government pertubuhan kebajikan. Pusat Ehsan Al-Ameerah Al-Hajjah Maryam telah ditubuhkan pada tahun 2000, dan menumpukan dalam menyediakan program pendidikan, perkhidmatan pemulihan dan latihan untuk individu berkeperluan khas. Pusat Ehsan mengiktiraf keperluan untuk Sekolah Berkeperluan Khas untuk melengkapkan usaha kerajaan dan menangani jurang dalam ekosistem pendidikan semasa",
			description2:
				"Pada tahun 2021, Pusat Ehsan telah diberi peluang dan pengesahan oleh Kementerian Pendidikan, Jabatan Pendididkan Swasta untuk membangunkan sebuah sekolah untuk pelajar berkeperluan khas di Negara Brunei Darussalam. Melalui tahun-tahun pembangunan, Sekolah Al-Ameerah Al-Hajjah Maryam telah ditubuhkan pada tahun 2023, di mana kementerian pendidikan mengiktiraf sekolah tersebut sebagai sekolah berkeperluan khas.",
			description3:
				"Sekolah Al-Ameerah Al-Hajjah Maryam akan bekerjasama dengan Pusat Ehsan Al-Ameerah Al-Hajjah Maryam untuk memastikan pendekatan holistik yang tersedia dalam menjaga perkembangan pelajar. Sekolah Al-Ameerah Al-Hajjah Maryam bersedia untuk menyediakan perkhidmatan pendidikan, manakala Pusat Ehsan Al-Ameerah Al-hajjah Maryam akan menawarkan pelajar di sekolah tersebut dalam pelbagai perkhidmatan pemulihan, termasuk fisioterapi, terapi pekerjaan, terapi pertuturan dan bahasa, dan psikologi.",
		},
		vs: {
			title1: "Visi",
			p1: "Membolehkan komuniti orang Kurang Upaya untuk menuju kehidupan bermakna dalam masyarakat",
			title2: "Misi",
			p2: "Mendidik, membantu, dan menjaga orang-orang yang kurang upaya untuk mencapai potensi penuh mereka",
			title3: "Moto Sekolah",
			p3: "Membuka Potensi, Memperkasakan Perbezaan!",
		},
		sFacilities: {
			title: "Kemudahan sekolah",
			p1: "Kemudahan kami dilengkapi sepenuhnya dengan akses inklusif untuk semua pelajar. Infrastruktur sekolah kami terdiri daripada 6 bilik darjah di sekolah yang baru dibina untuk Pelajar Rendah, taman permainan inklusif, taman berkelah dan Pavilion di mana pelajar mempunyai makanan ringan dan waktu rehat.",
			p2: "1 Bilik Darjah Awal Tahun diperuntukkan di Bangunan Pemulihan, Aras 1 dengan perpustakaan sokongan dan kawasan permainan luar. Taman Terapi, Bilik Sensori dan kolam renang inklusif.",
		},

		Academics: {
			title: "Akademik",
			p1: "Setiap pelajar adalah unik dan individu dengan gaya pembelajaran dan kekuatan yang berbeza.",
			p2: "Kami memastikan untuk mengambil kira semua gaya pembelajaran yang berbeza, pelajar kemudiannya akan dapat mencapai potensi penuh mereka, di mana-mana sahaja.",
		},
		OurCurriculum: {
			title: "Kurikulum kami",
			vision: "Menjayakan Komuniti Keperluan Khas Ke Arah Kehidupan Bermakna dalam Masyarakat",
			mission:
				"Untuk mendidik, membantu dan mempeduli Orang Berkeperluan Khas untuk mencapai potensi penuh mereka",
			guidingPrinciple: [
				"Excellence",
				"Inclusive",
				"Student Centred",
				"Holistic",
				"Outcome Orientated",
				"Advocacy Driven",
				"Collaborative Efforts",
			],
			theCommunityTobe: [
				"Understand Self and the world around them",
				"Value diversity",
				"Be confident",
				"Be resilient",
				"Curious",
				"Manage Self in different situations",
			],
			p1: "Sekolah Al-Ameerah Al-Hajjah Maryam telah mengenal pasti empat bidang di mana pelajar memerlukan sokongan yang selaras dengan hasil yang diinginkan dalam Kurikulum Kebangsaan abad ke-21 Negara Brunei Darussalam.",
			p2: [
				"Kesedaran Diri - Fahami diri sendiri dan dunia di sekelilingnya.",
				"Adaptasi - Menguruskan diri dalam situasi yang berbeza",
				"Kepelbagaian - Value diversity and relate with others effectively",
				"Yakin, Berdaya Tahan dan Ingin Tahu. ",
			],
			p3: "Kami yakin dengan konsep pembelajaran sepanjang hayat. Pembelajaran harus menjadi proses berterusan sepanjang hayat bagi setiap individu, bukan hanya terhad kepada pendidikan formal pada zaman kanak-kanak dan dewasa muda. Ia juga melibatkan menambah ilmu pengetahuan, kemahiran dan kebolehan baharu yang boleh digunakan untuk pembangunan peribadi dan profesional.",
			p4: "Pembelajaran sepanjang hayat adalah penting untuk setiap individu, termasuk pelajar berkeperluan khas. Pembelajaran berterusan boleh memberi manfaat kepada pelajar berkeperluan khas dalam beberapa cara, termasuk kebolehan kognitif yang lebih baik, kemahiran sosial yang dipertingkatkan, peningkatan kebebasan dan peluang kerjaya yang lebih baik. Ia membantu mengukuhkan ingatan, perhatian dan tumpuan, di samping menggalakkan interaksi sosial dan kerjasama. Selain itu, ia boleh membawa kepada sara diri dan keyakinan yang lebih tinggi, serta prospek kerjaya yang lebih baik.",
		},
		EarlyYears: {
			title: "Tahun Awal",
			subtitle: "3 - 5 Tahun",
			p1: "Program Tahun Awal dan Sekolah Rendah melayani Pelajar yang berbeza. Sekolah Al-Ameerah Al-Hajjah Maryam percaya kepada Intervensi awal, mengenal pasti bahawa perjalanan perkembangan kanak-kanak bermula sejak bayi. Program Tahun Awal menyasarkan 3-5 tahun, mempromosikan pengetahuan asas-asas dan kesediaan pembelajaran untuk peralihan yang lebih lancar ke dalam pendidikan aliran utama. Program ini direka untuk melengkapkan pelajar dengan akademik dan kemahiran hidup berdikari, menyediakan pendekatan holistik untuk pembelajaran. Khususnya, Program Tahun Awal memenuhi keperluan pelajar yang berbeza, memastikan mereka menerima penjagaan dan pendidikan awal kanak-kanak yang berkualiti.",
			primeareas: {
				title: "3 Peringkat Utama",
				p1: "Kami menggunakan The Early Years Foundation Stage Framework yang mengutamakan tiga peringkat utama, dimana ialah sangat penting untuk asas pembelajaran kanak-kanak:",
				p2: [
					"Perkembangan Peribadi, Sosial dan Emosi, yang merangkumi keyakinan diri & kesedaran diri, mengendali perasaan & tingkah laku, dan menghasilkan perhubungan.",
					"Komunikasi dan Bahasa, yang merangkumi pendengaran dan perhatian, pemahaman, dan pertuturan.",
					"Perkembangan Fizikal, yang merangkumi pergerakan dan pengendalian, dan kesihatan dan penjagaan diri.",
				],
			},
			areasofLearning: {
				title: "Bidang Pembelajaran",
				p1: "Dari bidang utama, kami membina Bidang Pembelajaran Khusus kanak-kanak, yang termasuk:",
				p2: [
					"English; membaca dan menulis.",
					"Matematik; mengenali nombor, bentuk, ruang dan ukuran.",
					"Kefahaman Dunia; manusia dan masyarakat, dunia, dan teknologi.",
					"Seni dan Reka Bentuk Ekspresif; meneroka dan menggunakan media dan bahan, dan menjadi imaginatif.",
				],
			},
			p4: "Kami percaya bahawa menyediakan ruang yang gembira, terjamin dan yakin untuk pelajar belajar akan membina keyakinan mereka dan meningkatkan minat mereka untuk belajar dimana ia boleh memberi mereka peluang dalam mencapai potensi mereka.",
		},
		PrimarySchool: {
			title: "Tahun Rendah & Pertengahan",
			subtitle: "6 - 13 Tahun",
			p1: "Program tahun rendah dan pertengahan memenuhi keperluan pelajar berumur 6 - 13 tahun. Program ini bertujuan untuk menyampaikan pendekatan pendidikan yang berpusatkan pelajar, individu, dan berbeza. Program ini memberi tumpuan kepada mata pelajaran akademik functional asas seperti Literasi (Bahasa English dan Bahasa Melayu), Numerasi, dan Kemahiran Hidup. Selain itu, subjek bukan akademik seperti Pembinaan Karakter dan kesedaran Sosial dan Emosi digabungkan untuk membantu pelajar dengan kemahiran sosial.",
			functionalAcademics: {
				title: "Akademik Functional",
				p1: ["Literasi", "Maths", "Ugama", "Sains Sosial", "Arts"],
			},
			tppse: {
				title: "Pembelajaran Fizikal, Peribadi, Sosial & Emosi",
				p1: ["PE", "Penjagaan diri", "Sosial & Emosi", "Aktiviti Kehidupan Harian"],
			},
			p2: "Kurikulum Rendah kami disokong oleh Rangka Kerja Keperluan Khas, diterbitkan pada November 2022 oleh Jabatan Pendidikan Khas, Kementerian Pendidikan. Memperkemas kurikulum semasa kami telah dibangunkan secara dalaman oleh perunding pakar dari Singapura, Dr Vincent Yeo dan Pasukan Pusat Ehsan pada tahun 2015 dengan Kurikulum Keperluan Khas Jabatan Pendidikan Sekolah Rendah yang baru diterbitkan, kami telah menyesuaikan kurikulum kami untuk memenuhi matlamat individu pelajar.",
		},
		Religious: {
			title: "Pelajaran Hal Ehwal Ugama",
			p1: "Di Sekolah Al-Ameerah Al-Hajjah Maryam, kami percaya kepada pendekatan pendidikan holistik, termasuk mengajar pelajar tentang iman. Kami kini mempunyai tiga guru Ugama yang dipinjamkan oleh Kementerian Hal Ehwal Ugama untuk memberikan pengajaran agama kepada pelajar kami. Mereka mempelajari asas-asas Islam, termasuk solat lima waktu, membaca Al-Quran, dan Amali Agama.",
		},
		AssessmentsReporting: {
			title: "Penilaian & Laporan",
			p1: "institusi kami mengutamakan pembelajaran kanak-kanak daripada mengikut kurikulum sepenuhnya. Untuk mencapai matlamat ini, kami menggunakan penilaian dan data bukan sahaja untuk mengukur kemajuan tetapi juga untuk memaklumkan pengajaran kami. Penilaian formal dan tidak formal dijalankan secara teratur untuk mengukur pembelajaran kanak-kanak. Penilaian harian, pemerhatian, semakan kerja yang ditanda, dan sesi Soal Jawab membolehkan guru kami melaraskan rancangan pengajaran mereka agar lebih sesuai dengan keperluan kelas. Penilaian formal memberikan gambaran keseluruhan kemajuan kanak-kanak.",
			p2: "Laporan ini akan disediakan melalui Perjumpaan Ibu Bapa dan Guru yang diadakan secara penggal.",
		},
		SupportServices: {
			title: "Perkhidmatan Sokongan",
			subtitle: "Unit Pemulihan",
			p1: "Sekolah Al-Ameerah Al-Hajjah Maryam bekerjasama rapat dengan Unit Pemulihan di bawah jagaan Pusat Ehsan Al-Ameerah Al-Hajjah Maryam bagi memastikan pendekatan holistik; kami percaya bahawa campur tangan terapeutik adalah penting untuk individu yang memerlukan bantuan untuk mencapai tahap kefungsian dan kebebasan tertinggi mereka, tanpa mengira batasan pergerakan, ketidakupayaan pembelajaran atau defisit kemahiran sosial. Kepercayaan ini mendorong misi kami untuk menyediakan campur tangan ini kepada mereka yang memerlukan.",
			p2: "Unit Pemulihan kami, terdiri daripada Terapi Carakerja, Fisioterapi, Terapi Pertuturan dan Bahasa serta Psikologi.",
			Physiotherapy: {
				title: "Fisioterapi",
				p1: "Salah Satu profesion yang menguruskan kesihatan dan fungsi badan. Mereka menyediakan pelan strategik untuk melaksanakan pendekatan konservatif dalam meminimumkan had fungsi dan untuk meningkatkan kebebasan pelanggan yang diperlukan untuk memenuhi aktiviti dan keperluan harian mereka.",
			},
			occupational: {
				title: "Terapi Pekerjaan",
				p1: "Menyokong pelanggan di kawasan yang berbeza dalam aktiviti harian mereka (penjagaan diri, aktiviti masa lapang dan menjadi produktif) dan dapat berdikari dalam kehidupan seharian mereka.",
			},
			speech: {
				title: "Terapi Pertuturan & Bahasa",
				p1: "Ia adalah sains komunikasi pertuturan, bahasa, kefasihan, pemakanan dan menelan. bersama-sama dengan cara komunikasi bukan lisan dan lisan",
			},
			Psychology: {
				title: "Psikologi",
				p1: "Peranan Psikologi dalam Memahami Neurodiversiti dan Memupuk kemasukan. Psikologi ada di sekeliling kita, membentuk pengalaman kita dan pelajaran yang kita pelajari dalam kehidupan. Ia menawarkan pandangan untuk mengkaji sebab individu berkelakuan seperti yang mereka lakukan, dan bagaimana persekitaran mereka boleh mempengaruhi mereka. Ini amat penting apabila melibatkan komuniti keperluan khas. Malangnya, ramai orang tidak mengetahui ciri-ciri individu neurodivergen, seperti mereka yang mempunyai Autisme atau Sindrom Down. Dengan meluangkan masa untuk memahami keperluan unik mereka, kita boleh mewujudkan persekitaran inklusif yang memupuk pertumbuhan mereka.",
			},
		},
		ContactUs: {
			title1: "Pentadbiran Sekolah",
			title2: "Pengetua Sekolah",
			title3: "Emel Sekolah",
			title4: "Pejabat Am",
			title5: "Emel",
			title6: "Laman Web",
			title7: "Unit Rehabilitasi",
			title8: "Pentadbiran",
			title9: "Pejabat Kewangan",
		},
		career: {
			title: "Kerjaya",
			p1: "Berminat untuk menyertai kami? Muat naik maklumat dan resume awda. Kami akan menghubungi awda secepat mungkin!",
			label1: "Nama penuh*",
			label2: "Emel*",
			label3: "No.vtelefon *",
			label4: "Muat naik resume*",
			label5: "Pilih Fail untuk dimuat naik",
		},
		footer: {
			quick: "Pautan Pantas",
			social: "Sosial",
		},

		calendar: {
			title: "Kalendar penggal sekolah",
		},
	},
];

export const EMP = {
	BOD: [
		{
			name: "Dato Paduka Dr Haji Omar Bin Haji Abd Khalid",
			imgUrl: dummy,
		},
		{
			name: "Dato Paduka Dr Haji Affendy bin POKSMDSP Haji Abidin",
			imgUrl: dummy,
		},
		{
			name: "Audrey Choo Wah",
			imgUrl: dummy,
		},
	],
	principal: [
		{
			name: "Nurussa'adatul Firdaus Saifulbahri",
			imgUrl: picture,
			qualification: "MA: Inclusive and Special Education University College London",
			specialty: "",
		},
	],
	Teachers: [
		{
			name: "Dk Nursyahirah Pg Anak Mohd Joffri",
			imgUrl: dksyahirah,
			qualification: "Certificate in Early Years Childhood International Brunei School",
			specialty: "",
		},
		{
			name: "Aziernie Marezza Shahzianna",
			imgUrl: ezza,
			qualification: "Diploma in Child Services International Brunei School",
			specialty: "",
		},
		{
			name: "Nurulain Affandi",
			imgUrl: ain,
			qualification: "Diploma in Child Services International Brunei School",
			specialty: "Bengkel Pentingnya Kommunikasi Tanpa Lisan(Braill), ",
			specialtyLocation: "Ministry of Education, Brunei Darussalam",
			specialty2: "Sign Language Trainee,",
			specialty2Location: "SEMEO VOCTEC",
		},
		// {
		//   name: "name",
		//   imgUrl: dummy,
		//   qualification: "Sign Language Trainee SEMEO VOCTEC",
		// },
		// {
		//   name: "name",
		//   imgUrl: dummy,
		//   qualification:
		//     "Bengkel Pentingnya Kommunikasi Tanpa Lisan(Braill) Ministry of Education, Brunei Darussalam",
		// },
		{
			name: "Waadiah Abd Wahid",
			imgUrl: wadiah,
			qualification: "BSC: Chemistry University Brunei Darussalam",
			specialty: "Introductory to Special Needs Jabatan Pendidikan Khas,",
			specialtyLocation: "Ministry of Education Brunei Darussalam",
		},
		// {
		//   name: "name",
		//   imgUrl: dummy,
		//   qualification:
		//     "Introductory to Special Needs Jabatan Pendidikan Khas, Ministry of Education Brunei Darussalam",
		// },
	],
	Tassistants: [
		{
			name: "Nurul Nadhirah Duraman",
			imgUrl: nadirah,
			qualification: "",
		},
		{
			name: "DK Nursyamsina Pg Anak Mohd Joffri",
			imgUrl: eyna,
			specialty: "Certificate in Supporting Teaching and Learning in Schools (Level 2),",
			specialtyLocation: "International School Brunei",
			qualification: "",
		},
		{
			name: "Adiman Saiman",
			imgUrl: adiman,
			qualification: "Diploma in Information Technology Micronet International College",
		},
	],
	SupportStaff: [
		{
			name: "Mark Edren Quilapio Laad",
			imgUrl: mark,
			qualification: "BSC, Nursing Philipines",
		},
	],
	Admin: [
		{
			name: "Jasmina Hamid",
			imgUrl: jHamid,
			qualification: "Head of Administration",
		},
	],
	FinanceOfficer: [
		// {
		//   name: "name",
		//   imgUrl: dummy,
		//   qualification: "",
		// },
	],
};

export const FACILITIES = {
	facName: [
		"Classroom",
		"Library",
		"Occupational Therapy Room",
		"Physio Therapy Room",
		"Reading Corner",
		"Safe Zones",
		"Sensory Room",
		"Speech Language Therapy Room",
		"Surau",
		"Swimming Pool",
	],
	facImg: [
		classroom,
		library,
		occupational,
		physio,
		reading,
		safezones,
		sensory,
		speech,
		surau,
		swimmingpool,
	],
};


