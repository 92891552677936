import React from "react";
import styled from "styled-components";
import Banner from "./OurSchoolComponents/Banner";
import AboutUs from "./OurSchoolComponents/AboutUs";
import Founders from "./OurSchoolComponents/Founders";
import VisionMission from "./OurSchoolComponents/VisionMission";
import OurTeam from "./OurSchoolComponents/OurTeam";
import Facilities from "./OurSchoolComponents/Facilities";
import background from "../assets/backgrounds/background.png";
import useDocumentTitle from "../Titlemanagement.jsx";
import { Writeups } from "../constant";

const OurSchool = ({ aboutUs, fWelcome, visionmission, team, schoolfacilities, Writeups }) => {
  useDocumentTitle("Sekolah Al-Ameerah Al-Hajjah Maryam | Our School");

  return (
    <Body>
      <Banner Writeups={Writeups} />
      <AboutUs scroll={aboutUs} Writeups={Writeups} />
      {/* <Founders scroll={fWelcome} /> */}
      <VisionMission scroll={visionmission} Writeups={Writeups} />
      <OurTeam scroll={team} Writeups={Writeups} />
      <Facilities scroll={schoolfacilities} Writeups={Writeups} />
    </Body>
  );
};
export default OurSchool;

const Body = styled.div`
  min-height: calc(100vh);
  background-image: url(${background});
  background-size: contain;
`;
