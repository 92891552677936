import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "./AdmissionComponents/Banner.jsx";
import AppProcess from "./AdmissionComponents/AppProcess";

import Fees from "./AdmissionComponents/Fees";
import Cal from "./AdmissionComponents/Cal";
import Faq from "./AdmissionComponents/Faq";
import News from "./AdmissionComponents/News";
import background from "../assets/backgrounds/background.png";
import useDocumentTitle from "../Titlemanagement.jsx";
const Admission = ({ appProcess, adFees, schoolCalendar, FAQ, news, Writeups, nav }) => {
  useDocumentTitle("Sekolah Al-Ameerah Al-Hajjah Maryam | Admission");
  return (
    <>
      <Body>
        <Banner Writeups={Writeups} />
        <AppProcess scroll={appProcess} Writeups={Writeups} />
        <Fees scroll={adFees} Writeups={Writeups} />
        <Cal scroll={schoolCalendar} Writeups={Writeups} nav={nav} />
        <Faq scroll={FAQ} Writeups={Writeups} />
        <News scroll={news} Writeups={Writeups} />
      </Body>
    </>
  );
};

export default Admission;

//Main Body Height
const Body = styled.div`
  min-height: calc(100vh);
  background-image: url(${background});
  background-size: contain;
`;
