/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import styled from "styled-components";
import adfees from "../../assets/sectionPicture/admission-fees.png";
import background from "../../assets/backgrounds/background.png";
const Fees = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("admission");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <FeesBody ref={scroll} id='feesSection'>
      <Content>
        <WriteupsFees>
          <h1>{Writeups[0].addFees.title}</h1>
          <p>{Writeups[0].addFees.content.p1}</p>
          <p>{Writeups[0].addFees.content.p2}</p>
          <p>{Writeups[0].addFees.content.p3}</p>
        </WriteupsFees>
        <img src={adfees} alt='Admission Fees' />
      </Content>
    </FeesBody>
  );
};

export default Fees;

const FeesBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: row-reverse;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  > img {
    width: 500px;
    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-left: auto;
    }
  }
  @media (max-width: 820px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
`;

const WriteupsFees = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8.11%;

  @media (max-width: 820px) {
    margin-left: 0;
  }
  > h1 {
    font-size: 50px;
    color: #55833e;
    margin: 0;
    margin-left: 20px;
  }

  > p {
    font-family: "Mulish";
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  p:first-of-type {
    margin-top: 25px; /* Resetting the margin for the first <p> */
  }

  p:nth-of-type(2) {
    margin-top: 20px; /* Resetting the margin for the first <p> */
  }
  p:nth-of-type(3) {
    margin-top: 20px; /* Resetting the margin for the first <p> */
  }
`;
