import React, { useEffect } from "react";
import styled from "styled-components";
import earlyYears from "../../assets/sectionPicture/early-years.png";
const EarlyYears = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <EarlyYearsBody ref={scroll} id='earlyYears'>
      <EarlyYearsCon>
        <img src={earlyYears} alt='early-years' />
        <WriteupsBod>
          <h1>{Writeups[0].EarlyYears.title}</h1>
          <h3>{Writeups[0].EarlyYears.subtitle}</h3>
          <p>{Writeups[0].EarlyYears.p1}</p>
        </WriteupsBod>
      </EarlyYearsCon>
      {/* <EarlyYearsCon2>
        <img src={earlyYears} alt='early-years' />
        <WriteupsBod>
          <p>{Writeups[0].EarlyYears.p4}</p>
          <p>{Writeups[0].EarlyYears.p5.title}</p>
          <li>{Writeups[0].EarlyYears.p5.p1}</li>
          <li>{Writeups[0].EarlyYears.p5.p2}</li>
          <li>{Writeups[0].EarlyYears.p5.p3}</li>
          <li>{Writeups[0].EarlyYears.p5.p4}</li>
          <li>{Writeups[0].EarlyYears.p5.p5}</li>
          <li>{Writeups[0].EarlyYears.p5.p6}</li>
          <p>{Writeups[0].EarlyYears.p6.title}</p>
          <p>{Writeups[0].EarlyYears.p6.description}</p>
        </WriteupsBod>
      </EarlyYearsCon2> */}
      <BoxCon>
        <PerBoxCon>
          <BoxContentPrime>
            <h1>{Writeups[0].EarlyYears.primeareas.title}</h1>
            <p>{Writeups[0].EarlyYears.primeareas.p1}</p>
            <ul>
              {Writeups[0].EarlyYears.primeareas.p2.map((sub, index) => (
                <li key={index}>{sub}</li>
              ))}
            </ul>
          </BoxContentPrime>
        </PerBoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].EarlyYears.areasofLearning.title}</h1>
            <p>{Writeups[0].EarlyYears.areasofLearning.p1}</p>
            <ul>
              {Writeups[0].EarlyYears.areasofLearning.p2.map((sub, index) => (
                <li key={index}>{sub}</li>
              ))}
            </ul>
          </BoxContentAreas>
        </PerBoxCon>
      </BoxCon>
      <Closing>
        <p>{Writeups[0].EarlyYears.p4}</p>
      </Closing>
    </EarlyYearsBody>
  );
};

export default EarlyYears;

const EarlyYearsBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  margin-bottom: 150px;
`;
const EarlyYearsCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  width: 100%;
  > img {
    width: 500px;

    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-right: auto;
    }
  }
  /* > img:nth-child(2) {
    @media (max-width: 820px) {
      display: none;
    }
  } */
`;

const EarlyYearsCon2 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  width: 100%;
  > img {
    width: 500px;

    @media (max-width: 500px) {
      width: 80%;
    }
  }
  /* > img:nth-child(2) {
    @media (max-width: 820px) {
      display: none;
    }
  } */
`;

const WriteupsBod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 9%;
  @media (max-width: 820px) {
    margin-right: 0;
  }
  > h1 {
    font-size: 50px;
    color: #55833e;
    margin: 0;
    margin-left: 30px;
    margin-right: 30px;
  }
  > h3 {
    font-size: 25px;
    color: #000;
    font-family: "Mulish";
    font-weight: 900px;
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
  > p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    margin-left: 30px;
    margin-top: 0px;
    margin-right: 30px;
    text-align: justify;
  }
  > p:first-of-type {
    margin-top: 10px;
  }
  > li {
    font-family: "Mulish";
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    margin-left: 50px;
    margin-top: 2px;
    margin-right: 50px;
    text-align: justify;
  }

  > li:first-of-type {
  }
`;

const BoxCon = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  margin-top: 30px;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PerBoxCon = styled.div`
  display: flex;
  flex: 1;
  background-color: #e5d6eb;
  border-radius: 30px;

  h1 {
    color: #975ab6;
    font-size: 30px;
  }
  p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
  }
  li {
    font-family: "Mulish";
    font-size: 20px;
  }
`;

const BoxContentPrime = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 30px 25px;
`;

const BoxContentAreas = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 25px 30px 25px;
`;

const Closing = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  p {
    font-family: "Mulish";
    font-size: 20px;
  }
`;
