import { useEffect } from "react";

function useDocumentTitle(title) {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = title;

    return () => {
      document.title = originalTitle; // Restore original title on component unmount
    };
  }, [title]);
}

export default useDocumentTitle;
