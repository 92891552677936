import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Drawer from "./HeaderComponents/Drawer";
import OurTeam from "../screens/OurSchoolComponents/OurTeam";

//Header Component
const Header = ({
  routes,
  handleNavRouteClick,
  showTabBar,
  activeLink,
  toggleDrawer,
  isopen,
  scrolltosection,
  appProcess,
  adFees,
  schoolCalendar,
  FAQ,
  news,
  aboutUs,
  fWelcome,
  visionmission,
  team,
  schoolfacilities,
  academics,
  curriculums,
  earlyYears,
  primarySchool,
  RA,
  assessments,
  supportServices,
  toggleLanguage,
}) => {
  const [isScrolledToTabBar, setIsScrolledToTabBar] = useState(false);

  // useEffect(() => {
  //   sessionStorage.setItem("activeLink", activeLink);
  // }, [activeLink]);

  const activeSubRoutes = routes.find((route) => route.name === activeLink)?.subRoutes || [];

  const [language, setLanguage] = useState(false);

  const toggleButton = () => {
    toggleLanguage();
    setLanguage(!language);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbarHeight = 120; // Assuming the height of the NavBar is 120px, adjust accordingly.

      if (window.scrollY > navbarHeight) {
        setIsScrolledToTabBar(true);
      } else {
        setIsScrolledToTabBar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToSection = (section) => {
    switch (section) {
      case "Application Process":
      case "Proses Permohonan":
        scrolltosection(appProcess);
        break;
      case "Admission Fees":
      case "Yuran Kemasukan":
        scrolltosection(adFees);
        break;
      case "School Calendar":
      case "Kalendar Sekolah":
        scrolltosection(schoolCalendar);
        break;
      case "FAQ":
      case "Soalan Lazim":
        scrolltosection(FAQ);
        break;
      case "In The News":
      case "Berita":
        scrolltosection(news);
        break;
      case "About Us":
      case "Tentang Kami":
        scrolltosection(aboutUs);
        break;
      case "Founder's welcome":
        scrolltosection(fWelcome);
        break;
      case "Vision & Mission":
      case "Visi & Misi":
        scrolltosection(visionmission);
        break;
      case "Our Team":
      case "Team Kami":
        scrolltosection(team);
        break;
      case "Kemudahan Sekolah":
      case "School Facilities":
        scrolltosection(schoolfacilities);
        break;
      case "Academics":
      case "Akademik":
        scrolltosection(academics);
        break;
      case "Our Curriculum":
      case "Kurikulum Kami":
        scrolltosection(curriculums);
        break;
      case "Early Years":
      case "Tahun Awal":
        scrolltosection(earlyYears);
        break;
      case "Primary & Middle Years":
      case "Tahun Rendah & Pertengahan":
        scrolltosection(primarySchool);
        break;
      case "Religious Lessons":
      case "Pelajaran Agama":
        scrolltosection(RA);
        break;
      case "Assesments":
      case "Penilaian":
        scrolltosection(assessments);
        break;
      case "Support Services":
      case "Perkhidmatan Sokongan":
        scrolltosection(supportServices);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Drawer
        routes={routes}
        isopen={isopen}
        toggleDrawer={toggleDrawer}
        scroll={scrolltosection}
        adFees={adFees}
      />
      <NavBar>
        <NavBarContent>
          <DrawerButton onClick={toggleDrawer}>
            <StyledFaBars />
          </DrawerButton>
          <Logo src={logo} alt='School Logo' />
          <RightNav>
            {routes.map((route) => (
              <NavRoute
                isActive={activeLink === route.name}
                onClick={() => handleNavRouteClick(route.name)}
                to={route.link}>
                {route.name}
              </NavRoute>
            ))}
            <Button onClick={toggleButton} language={language}>
              {language ? "English" : "Malay"}
            </Button>
          </RightNav>
        </NavBarContent>
      </NavBar>
      <NavBar2
        isFixed={isScrolledToTabBar}
        shouldDisplay={activeSubRoutes.length > 0}
        isVisible={showTabBar}>
        {showTabBar && (
          <TabBar id='tabBar'>
            {activeSubRoutes.map((sub, index) => (
              <>
                <TabLink
                  key={index}
                  onClick={() => {
                    handleScrollToSection(sub.name);
                    console.log(sub.name);
                  }}>
                  {sub.name}
                </TabLink>

                {index !== activeSubRoutes.length - 1 && <Spacer />}
              </>
            ))}
          </TabBar>
        )}
      </NavBar2>
    </div>
  );
};

export default Header;

//styling for desktop
const NavBarContent = styled.div`
  @media (max-width: 1024px) {
    height: 70px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  padding: 1rem;
  margin: 0 auto;
  max-width: 100%;
  z-index: 1010;
`;

const NavBar = styled.div`
  @media (max-width: 1024px) {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  position: relative;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

const NavBar2 = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  background-color: #ebd6eb;
  display: ${(props) => (props.isVisible && props.shouldDisplay ? "block" : "none")};
  opacity: ${(props) => (props.isVisible && props.shouldDisplay ? 1 : 0)};
  transform: translateY(${(props) => (props.isVisible && props.shouldDisplay ? "0" : "-10px")});
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // This will fade and slide the TabBar.
  pointer-events: ${(props) =>
    props.isVisible && props.shouldDisplay
      ? "auto"
      : "none"}; // Make sure it's not clickable when invisible
  position: ${(props) => (props.isFixed ? "fixed" : "relative")};
  top: 0; // Adjust according to your NavBar height.
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  display: block;
  height: 120px; // Adjust according to your preference
  margin-right: 10px; // Space after logo, adjust as needed

  @media (max-width: 1024px) {
    height: 90px;
    margin: 0;
  }
`;

const RightNav = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  display: flex;
  gap: 1.5rem;
`;

const NavRoute = styled(Link)`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 1142px) {
    font-size: 20px;
    padding: 5px 12px;
  }
  position: relative;
  text-decoration: none;
  color: black;
  padding: 9px 16px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 22px;
  font-size: 1.4rem;
  &::after {
    content: "";
    display: block;
    height: 3.5px;
    background-color: #aed768;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }
  ${(props) =>
    props.isActive &&
    `
    &::after {
      transform: scaleX(1);  // Show the full width of the line when active.
      transform-origin: bottom left;  // The line will shrink back to the left side.
    }
  `}
`;

const TabBar = styled.div`
  max-width: 100%;
  background-color: #ebd6eb;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  padding: 1.8rem 0;
  top: 0; // Adjust according to your NavBar height.
  left: 0;
  right: 0;
  z-index: 1000;
`;

const TabLink = styled(Link)`
  text-decoration: none;
  color: #9d8ea3;
  font-size: 20px;
  /* padding-right: ${(props) => (props.isSpecialRouteLength ? "130px" : "72px")}; */
  /* padding-right: ${(props) => (props.index3 ? "5%" : "7%")}; */
  /* border-right: 1px solid black; */
  margin-left: 2%;
  margin-right: 2%;
  font-family: "Mulish";
  font-weight: 500;
  &:last-child {
    border-right: none; /* No border on the right side of the last tab */
  }
  &:hover {
    color: #4f1964;
    transition: opacity 0.3ms ease-in-out;
  }
`;

const Spacer = styled.div`
  display: flex;
  height: 25px;
  width: 2px;
  background-color: grey;
`;

const Button = styled.button`
  background-color: ${(props) => (props.language ? "#e5d6eb" : "#e8f9e045")};
  border: ${(props) => (props.language ? "1px solid #4f1964" : "1px solid #aed768;")};
  border-radius: 25px;
  width: 120px;
  padding: 10px 20px;
  font-size: 15px;
  color: ${(props) => (props.language ? "#4f1964" : "#aed768")};
  font-family: "Mulish";
  font-weight: 900;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 90px;
  }
`;

const StyledFaBars = styled(FaBars)`
  /* Add your styles here */
  height: 80px;
  width: 30px;
  color: black;
`;

const DrawerButton = styled.button`
  all: unset;
  font-size: 2rem;
  display: grid;
  @media (min-width: 1025px) {
    display: none;
  }
`;
