import React, { useEffect } from "react";
import styled from "styled-components";
import primarySchool from "../../assets/sectionPicture/academics.png";
const PrimarySchool = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("learn");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <PrimarySchoolBody ref={scroll} id='primarySchool'>
      <PrimarySchoolCon>
        <img src={primarySchool} alt='Primary School' />
        <WriteupsBod>
          <h1>{Writeups[0].PrimarySchool.title}</h1>
          <h3>{Writeups[0].PrimarySchool.subtitle}</h3>
          <p>{Writeups[0].PrimarySchool.p1}</p>
        </WriteupsBod>
      </PrimarySchoolCon>
      <BoxCon>
        <PerBoxCon>
          <BoxContentAreas>
            <h1>{Writeups[0].PrimarySchool.functionalAcademics.title}</h1>
            <ul>
              {Writeups[0].PrimarySchool.functionalAcademics.p1.map((sub, index) => (
                <li key={index}>{sub}</li>
              ))}
            </ul>
          </BoxContentAreas>
        </PerBoxCon>
        <PerBoxCon>
          <BoxContentPrime>
            <h1>{Writeups[0].PrimarySchool.tppse.title}</h1>
            <ul>
              {Writeups[0].PrimarySchool.tppse.p1.map((sub, index) => (
                <li key={index}>{sub}</li>
              ))}
            </ul>
          </BoxContentPrime>
        </PerBoxCon>
      </BoxCon>
      <Closing>
        <p>{Writeups[0].PrimarySchool.p2}</p>
      </Closing>
    </PrimarySchoolBody>
  );
};

export default PrimarySchool;

const PrimarySchoolBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`;

const PrimarySchoolCon = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  > img {
    width: 500px;
    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-left: auto;
    }
  }
`;

const WriteupsBod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 9%;
  @media (max-width: 820px) {
    margin-left: 0;
  }
  > h1 {
    font-size: 50px;
    color: #975ab6;
    margin: 0;
    margin-left: 30px;
    margin-right: 30px;
  }
  > h3 {
    font-size: 25px;
    color: #000;
    font-family: "Mulish";
    font-weight: 900px;
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
  > p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    margin-left: 30px;
    margin-top: 15px;
    margin-right: 30px;
    text-align: justify;
  }
  > p:first-of-type {
    margin-top: 15px;
  }
`;

const BoxCon = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  margin-top: 5px;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PerBoxCon = styled.div`
  display: flex;
  flex: 1;
  background-color: #aed768;
  border-radius: 30px;

  h1 {
    color: #55833e;
    font-size: 30px;
  }
  p {
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
  }
  li {
    font-family: "Mulish";
    font-size: 20px;
  }
`;

const BoxContentPrime = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 30px 25px;
`;

const BoxContentAreas = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 25px 30px 25px;
`;

const Closing = styled.div`
  display: flex;
  width: 78%;
  margin: 0 auto;
  margin-top: 30px;
  p {
    font-family: "Mulish";
    font-size: 20px;
  }
`;
