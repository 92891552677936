import React, { useEffect } from "react";
import styled from "styled-components";
import leaf from "../../assets/backgrounds/leaf-background-white.png";
const VisionMission = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("ourschool");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <VisionBody ref={scroll} id='visionmission'>
      <VisionMissionCon>
        <VisionContainer>
          <h1>{Writeups[0].vs.title1}</h1>
          <h2>
            <q>{Writeups[0].vs.p1}</q>
          </h2>
        </VisionContainer>
        <MissionContainer>
          <h1>{Writeups[0].vs.title2}</h1>
          <h2>
            <q>{Writeups[0].vs.p2}</q>
          </h2>
        </MissionContainer>
      </VisionMissionCon>
      <Moto>
        <h1>{Writeups[0].vs.title3}</h1>
        <h2>
          <q>{Writeups[0].vs.p3}</q>
        </h2>
      </Moto>
    </VisionBody>
  );
};

export default VisionMission;

const VisionBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
`;

const VisionMissionCon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const VisionContainer = styled.div`
  display: flex;
  background-color: #55833e;
  background-image: url(${leaf});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 30px;
  width: 36%;
  margin-right: 1.5%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2%;

  > h1 {
    font-size: 40px;
    color: white;
    font-weight: 700;
    margin: 0 0 35px 0;
    @media (max-width: 375px) {
      font-size: 30px;
      color: white;
      font-weight: 700;
      margin: 0 0 35px 0;
    }
  }
  > h2 {
    font-size: 25px;
    color: white;
    font-weight: 600;
    margin: 0;
    @media (max-width: 375px) {
      font-size: 20px;
      color: white;
      font-weight: 700;
      margin: 0 0 35px 0;
    }
  }
  @media (max-width: 500px) {
    width: 81%;
    margin: 0 auto;
    padding: 1%;
  }
`;

const MissionContainer = styled.div`
  display: flex;
  background-color: #7cb258;
  background-image: url(${leaf});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 30px;
  width: 36%;
  margin-left: 1.5%;
  flex-direction: column;
  padding: 2%;

  > h1 {
    display: flex;
    flex-wrap: wrap;
    font-size: 40px;
    color: white;
    font-weight: 700;
    margin: 0 0 35px 0;
    @media (max-width: 375px) {
      font-size: 30px;
      color: white;
      font-weight: 700;
      margin: 0 0 35px 0;
    }
  }
  > h2 {
    font-size: 25px;
    color: white;
    font-weight: 600;
    margin: 0 0 35px 0;
    @media (max-width: 375px) {
      font-size: 20px;
      color: white;
      font-weight: 700;
      margin: 0 0 35px 0;
    }
  }
  @media (max-width: 500px) {
    width: 81%;
    margin: 0 auto;
    margin-top: 25px;
    padding: 1%;
  }
`;

const Moto = styled.div`
  display: flex;
  width: 81%;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin: 25px auto 0 auto;
  border-radius: 30px;
  background-color: #aed768;
  background-image: url(${leaf});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1%;

  > h1 {
    font-size: 40px;
    color: white;
    margin-top: 10px;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 20px;
    @media (max-width: 375px) {
      font-size: 30px;
      color: white;
      margin-top: 10px;
      margin-left: 3%;
      margin-right: 3%;
      margin-bottom: 20px;
    }
  }
  > h2 {
    font-size: 25px;
    margin-left: 3%;
    margin-top: 0;
    color: white;
    @media (max-width: 375px) {
      font-size: 30px;
      color: white;
      font-size: 25px;
      margin-left: 3%;
      margin-top: 0;
    }
  }
`;
