import React from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import cam from "../../assets/Cambridge-Logo.png";
import pearson from "../../assets/pearson-edexcel.png";
import bronze from "../../assets/Bronze-logo.png";
import smha from "../../assets/smha.jpg";
const Logos = () => {
  return (
    <LogoContainer>
      <Logo src={cam} alt="School Logo" />
      <Logo src={pearson} alt="School Logo" />
      <Logo src={bronze} alt="School Logo" />
      <Logo src={smha} alt="School Logo" />
    </LogoContainer>
  );
};

export default Logos;

const LogoContainer = styled.div`
  @media (max-width: 768px) {
    margin: 225px auto 0px auto;
  }
  display: flex;
  align-items: center;
  height: 7.5rem;
  margin: 200px auto;

  /* background-color: #ffffff; // or any color you prefer */
  /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.9); // optional shadow for a bit of depth */
  max-width: 1300px;
  justify-content: space-evenly;

  @media (max-width: 736px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // 2 columns: ;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 15px;
    margin-top: 300px;
    align-items: start;
    // spacing between logos
    // adjust margin as needed for smaller screens
  }

  @media (max-width: 320px) {
    display: -webkit-grid;
    display: grid;
    grid-template-columns: 1fr 1fr; // 2 columns
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 0px;
    align-items: start;
    // spacing between logos
    // adjust margin as needed for smaller screens
  }
`;

const Logo = styled.img`
  flex: 1;
  height: 100%; // Adjust according to your preference
  margin-left: 40px; // To provide spacing between logos, you can adjust as necessary
  margin-right: 40px; // Space after logo, adjust as needed
  @media (max-width: 1301px) {
    height: 80%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  }
  @media (max-width: 1024px) {
    height: 70%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  }

  @media (max-width: 896px) {
    height: 60%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  }

  @media (max-width: 896px) {
    height: 55%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    height: 55%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  }
  @media (max-width: 736px) {
    height: 100px;
    width: 165px;
    // Increased from 5% to make it more visible
  }
  @media (max-width: 427px) {
    height: 80px;
    width: 140px;
    // Increased from 5% to make it more visible
  }

  @media (max-width: 320px) {
    height: 60px;
    width: 120px;
    // Increased from 5% to make it more visible
  }

  /* @media (max-width: 590px) {
    height: 5%; // Reducing the height for screens at or below 768px
    margin-left: 20px; // Reducing the spacing for smaller screens
    margin-right: 20px;
  } */
`;
