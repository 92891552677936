/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

//dummy pic
import bronze from "../../assets/Bronze-logo.png";
import styled from "styled-components";

const News = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("admission");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowBack
        className={className}
        style={{
          ...style,
          display: "block",
          left: "10px",
          fontSize: "20px",
          color: "#333",
        }}
        onClick={onClick}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowForward
        className={className}
        style={{
          ...style,
          display: "block",
          right: "10px",
          fontSize: "20px",
          color: "#333",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <NewsBody ref={scroll} id='newsSection'>
      <NewsC>
        <h1>{Writeups[0].news.title}</h1>
        <Container>
          <h3>{Writeups[0].news.p1}</h3>
        </Container>
        {/* <Slider {...settings}>
          <Container>
            <Container2>
              <img src={bronze} />
              <h3>Grand Opening</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                ante....
              </p>
              <Button>Read More</Button>
            </Container2>
          </Container>
          <Container>
            <Container2>
              <img src={bronze} />
              <h3>Orientation</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                ante....
              </p>
              <Button>Read More</Button>
            </Container2>
          </Container>
          <Container>
            <Container2>
              <img src={bronze} />
              <h3>Football</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                ante....
              </p>
              <Button>Read More</Button>
            </Container2>
          </Container>
          <Container>
            <Container2>
              <img src={bronze} />
              <h3>1</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                ante....
              </p>
              <Button>Read More</Button>
            </Container2>
          </Container>
        </Slider> */}
      </NewsC>
    </NewsBody>
  );
};

export default News;

const NewsBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const NewsC = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  border-radius: 30px;
  background-color: #e5d6eb;
  > h1 {
    font-size: 40px;
    color: #975ab6;
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex !important;
  justify-content: center;
  margin-bottom: 40px;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
  border-radius: 30px;
  > img {
    flex-wrap: wrap;
    border-radius: 20px;
    width: 90%;

    padding: 5%;
  }
  > h3 {
    font-family: "Mulish";
    font-weight: 900;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 3px;
    margin-left: 28px;
  }
  > p {
    font-family: "Mulish";
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    margin-left: 28px;
    margin-right: 28px;
  }
`;

const Button = styled.button`
  font-size: 18px;
  width: 40%;
  display: flex;
  justify-content: center;
  margin-left: 28px;
  margin-bottom: 20px;
  padding: 4%;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: #975ab6;
`;
