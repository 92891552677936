import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";
import address from "../../assets/icons/address.png";
import call from "../../assets/icons/call.png";
import email from "../../assets/icons/email.png";

const Links = ({ Writeups, routes, activeLink, handleNavRouteClick, setShowTabBar }) => {
  return (
    <>
      <QuickLinksBody>
        <QuickLinksTitle>
          <h1>{Writeups[0].footer.quick}</h1>
          <LinksContent>
            {routes.map((route, index) => (
              <QuickRoute
                isActive={activeLink === route.name}
                onClick={() => handleNavRouteClick(route.name)}
                key={index}
                to={route.link}
                isfirst={index === 0}
                islast={index === routes.length - 1}>
                {route.name}
              </QuickRoute>
            ))}
          </LinksContent>
          <SocMedContainer>
            <h2>{Writeups[0].footer.social}</h2>
            <a
              href='https://www.instagram.com/yourInstagramUsername/'
              target='_blank'
              rel='noopener noreferrer'>
              <AiOutlineInstagram size={35} />
            </a>
          </SocMedContainer>
        </QuickLinksTitle>
        <LineSpacer />
        <ContactContainer>
          <ContactsList>
            <SvgIcon src={address} alt='AddressIcon' />
            Kampong Bengkurong, BF1920
          </ContactsList>
          <ContactsList>
            <SvgIcon src={call} alt='AddressIcon' />
            +673 8303860
          </ContactsList>
          <ContactsList>
            <SvgIcon src={email} alt='AddressIcon' />
            admin@saaahm.com
          </ContactsList>
        </ContactContainer>
      </QuickLinksBody>
    </>
  );
};

export default Links;

const QuickLinksBody = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 300px;

  > h1 {
    font-size: 30px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 50px;
  }
`;
const QuickLinksTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;
const LinksContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const QuickRoute = styled(Link)`
  flex-wrap: wrap;
  text-decoration: none;
  display: flex;
  font-size: 20px;
  color: white;
  padding-left: ${(props) => (props.isfirst ? "0px" : "10px")};
  padding-right: 10px;
  border-right: ${(props) => (props.islast ? "none" : "1px solid white")};

  font-family: "Mulish";
  font-weight: 600;
  justify-content: center;
  align-items: center;
`;

const SocMedContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 50px;
  align-items: center;
  color: white;
  a {
    color: white;
  }
  h2 {
    font-size: 30px;
    margin: 0px 0px 0px 0px;
  }
`;

const LineSpacer = styled.div`
  background-color: white;
  width: 1px;
  height: inherit;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ContactsList = styled.li`
  color: white;

  @media (max-width: 768px) {
    margin-bottom: 14px;
  }
  display: flex;
  align-items: center;

  gap: 8px;
  font-family: "Mulish";
  font-size: 18px;
  /* &:first-child {
    margin-top: 0px;
  }
  margin-top: 10px; */

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const SvgIcon = styled.img`
  color: white;
  width: 20px;
  height: 20px;
`;
