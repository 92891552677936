import React, { useEffect } from "react";
import styled from "styled-components";
import aboutus1 from "../../assets/sectionPicture/about-us-1.png";
import aboutus2 from "../../assets/sectionPicture/about-us-2.png";

const AboutUs = ({ scroll, Writeups }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const scrollToSection = params.get("ourschool");
    const test = document.getElementById(scrollToSection);
    if (test) {
      const offset = test.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }, [window.location.search]);
  return (
    <AboutBody ref={scroll} id='aboutUs'>
      <Content>
        <WriteUpsBody>
          <h1>{Writeups[0].About.title}</h1>
          <p>{Writeups[0].About.description}</p>
        </WriteUpsBody>
        <img src={aboutus1} alt='AboutUs1' />
      </Content>
      <Content>
        <WriteUpsBody2>
          <p>{Writeups[0].About.description2}</p>
          <p>{Writeups[0].About.description3}</p>
        </WriteUpsBody2>
        <img src={aboutus2} alt='AboutUs2' />
      </Content>
    </AboutBody>
  );
};

export default AboutUs;

const AboutBody = styled.section`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  > img {
    width: 500px;
    @media (max-width: 600px) {
      width: 80%;
    }
    @media (max-width: 820px) {
      margin-left: auto;
    }
  }
  @media (max-width: 820px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  &:nth-child(2) {
    flex-direction: row-reverse;
    > img {
      @media (max-width: 820px) {
        display: none;
      }
    }
  }
`;

const WriteUpsBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7%;

  @media (max-width: 820px) {
    margin-left: 0;
  }

  > h1 {
    color: #55833e;
    font-size: 50px;
    margin: 0;
    margin-left: 50px;
  }
  > p {
    font-size: 20px;
    font-family: "Mulish";
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
    font-weight: 500;
    margin-left: 50px;
  }
`;

const WriteUpsBody2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 7%;
  > p {
    font-size: 20px;
    font-family: "Mulish";
    margin-right: 50px;
    text-align: justify;
    font-weight: 500;
    margin-left: 50px;
    margin-top: 0;
  }
  > p:nth-child(2) {
    font-size: 20px;
    font-family: "Mulish";
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
    font-weight: 500;
    margin-left: 50px;
    margin-top: 0;
  }
`;
