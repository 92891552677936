import React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import ExpandMenu from "../FooterComponent/ExpandMenu";
const Drawer = ({ isopen, toggleDrawer, routes, adFees, scroll }) => {
  return (
    <>
      {isopen && <Backdrop onClick={toggleDrawer} />}

      <SDrawer isOpen={isopen}>
        <RightNav>
          <SNavbarBrand>Menu</SNavbarBrand>
          <NavRoutes>
            {routes.map((route) => {
              if (route.subRoutes) {
                return (
                  <ExpandMenu
                    toggleDrawer={toggleDrawer}
                    route={route}
                    key={route.name}
                    adFees={adFees}
                    scroll={scroll}
                  />
                );
              }
              return (
                <NavRoute
                  onClick={toggleDrawer}
                  to={route.link}
                  key={route.name}>
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
        </RightNav>
      </SDrawer>
    </>
  );
};

export default Drawer;

const Backdrop = styled.div`
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;
const SDrawer = styled.div`
  z-index: 150;
  position: absolute;
  top: 0;
  height: 200vh;
  width: 80%;
  background-color: white;
  transition: 0.3s ease;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
`;

const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`;

const NavRoutes = styled.div``;

const NavRoute = styled(Link)`
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 5vw;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
`;

const SNavbarBrand = styled.h2`
  font-size: 10vw;
  margin: 0;
  padding: 0;
  font-family: "Fredoka";
`;
